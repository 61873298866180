import React, { useEffect, useState } from 'react';
import styles from './vehicleConnect.module.css';
import BaseView from '../../views/baseView/baseView';
import { CheckBox } from '../../views/inputs/inputs';
import { ServiceRequest, VehicleServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import VehicleList from './vehicleList';
import { PrimaryButton } from '../../views/buttons/buttons';
import { priceFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function VehicleConnect() {
  const [solutionList, setSolutionList] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [engines, setEngines] = useState([]);
  const [selectedEngines, setSelectedEngines] = useState([]);

  useEffect(() => {
    getSolutions();
  }, []);
  function getSolutions() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getSolutions,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setSolutionList(res.data);
        getVehicleTypes();
      })
      .catch((err) => {
      });
  }
  function getVehicleTypes() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleTypes,
      loading: true,
    })
      .then((res) => {
        let list = res.data;
        list = list.map((e) => ({ ...e, id: e.type, name: e.label }));
        setVehicleTypes(list);
      })
      .catch((err) => {
      });
  }
  function handleDataChange({ type, data }) {
    let endpoint = null;
    let body = null;
    switch (type) {
      case 'vehicleType':
        setManufacturers([]);
        setModels([]);
        setYears([]);
        setEngines([]);
        endpoint = apiEndpoints.vehicle.getBrandsForTypes;
        body = { vehicleTypes: data };
        break;
      case 'manufacturer':
        setModels([]);
        setYears([]);
        setEngines([]);
        endpoint = apiEndpoints.vehicle.getModelsForBrands;
        body = { brandIds: data };
        break;
      case 'model':
        setYears([]);
        setEngines([]);
        endpoint = apiEndpoints.vehicle.getGenerationsForModels;
        body = { modelIds: data };
        break;
      case 'year':
        setEngines([]);
        endpoint = apiEndpoints.vehicle.getEnginesForGenerations;
        body = { generationIds: data };
        break;
      case 'engine':
        setSelectedEngines(data);
        return;
      default:
        break;
    }
    if (endpoint && body) {
      VehicleServiceRequest({
        endpoint,
        body,
        method: 'POST',
        loading: true,
      })
        .then((res) => {
          switch (type) {
            case 'vehicleType':
              setManufacturers(res.data);
              break;
            case 'manufacturer':
              setModels(res.data);
              break;
            case 'model':
              setYears(res.data);
              break;
            case 'year':
              setEngines(res.data);
              break;
            case 'engine':
              break;
            default:
              break;
          }
        })
        .catch((err) => {
        });
    }
  }
  function handleSolutionCheck(id) {
    const newArr = [...solutionList];
    const index = newArr.findIndex((e) => e.id === id);
    if (index > -1) {
      newArr[index].selected = !newArr[index].selected;
      setSolutionList(newArr);
    }
  }
  function connectSolutions() {
    const body = [];
    selectedEngines.forEach((e) => {
      body.push({
        engineId: Number(e),
        solutions: solutionList.filter((s) => s.selected).map((t) => t.id),
      });
    });
    ServiceRequest({
      endpoint: apiEndpoints.admin.updateVehicleSolutions,
      loading: true,
      successMessage: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        clearAll();
      })
      .catch((err) => {
      });
  }
  function clearAll() {
    setManufacturers([]);
    setModels([]);
    setYears([]);
    setEngines([]);
    setSelectedEngines([]);
    const cleanVehicleTypes = vehicleTypes.map((e) => ({ ...e, selected: false }));
    setVehicleTypes(cleanVehicleTypes);
    const cleanSolutionData = solutionList.map((e) => ({ ...e, selected: false }));
    setSolutionList(cleanSolutionData);
  }
  return (
    <BaseView path="/ Tuning Options / Add Option">
      <div className={styles.main}>
        <div className={styles.comp}>
          <div className={`${styles.searchTitleCont} border-bottom`}>
            <div className="d-flex f-column f1">
              <div className={styles.t7}>{localStr()?.vehicleConnect?.t1}</div>
              <div className={styles.t8}>{localStr()?.vehicleConnect?.t2}</div>
            </div>
          </div>
          <div className={styles.cont}>
            <div className="d-flex m-t-24">
              <VehicleList
                list={vehicleTypes}
                title={localStr()?.vehicleConnect?.t3}
                searchPlaceHolder={localStr()?.vehicleConnect?.t4}
                dataChange={(e) => handleDataChange({ type: 'vehicleType', data: e })}
              />
              <VehicleList
                list={manufacturers}
                title={localStr()?.vehicleConnect?.t5}
                searchPlaceHolder={localStr()?.vehicleConnect?.t6}
                noDataMessage={localStr()?.vehicleConnect?.t7}
                formStyle="m-l-24"
                dataChange={(e) => handleDataChange({ type: 'manufacturer', data: e })}
              />
            </div>
            <div className="d-flex m-t-24">
              <VehicleList
                list={models}
                type="fullName"
                title={localStr()?.vehicleConnect?.t8}
                searchPlaceHolder={localStr()?.vehicleConnect?.t9}
                noDataMessage={localStr()?.vehicleConnect?.t10}
                dataChange={(e) => handleDataChange({ type: 'model', data: e })}
              />
              <VehicleList
                list={years}
                type="fullName"
                title={localStr()?.vehicleConnect?.t11}
                searchPlaceHolder={localStr()?.vehicleConnect?.t12}
                noDataMessage={localStr()?.vehicleConnect?.t13}
                formStyle="m-l-24"
                dataChange={(e) => handleDataChange({ type: 'year', data: e })}
              />
            </div>
            <div className="d-flex m-t-24">
              <VehicleList
                list={engines}
                type="fullName"
                listKey="engine"
                title={localStr()?.vehicleConnect?.t14}
                searchPlaceHolder={localStr()?.vehicleConnect?.t15}
                noDataMessage={localStr()?.vehicleConnect?.t16}
                dataChange={(e) => handleDataChange({ type: 'engine', data: e })}
              />
            </div>
          </div>
          <div className="d-flex f-column f1 m-t-32">
            <div className={styles.t7}>{localStr()?.vehicleConnect?.t17}</div>
            <div className={styles.t8}>
              {localStr()?.vehicleConnect?.t18}
            </div>
          </div>
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="w-50">{' '}</th>
                <th>{localStr()?.vehicleConnect?.t19}</th>
                <th>{localStr()?.vehicleConnect?.t20}</th>
                <th className="w-110">{localStr()?.vehicleConnect?.t21}</th>
              </tr>
            </thead>
            <tbody>
              {solutionList.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.vehicleConnect?.t22}
                </td>
              </tr>
              )}
              {solutionList.map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="w-50">
                    <div className="d-flex justify-center">
                      <CheckBox
                        checked={item.selected}
                        checkboxStyle={styles.checkbox}
                        toggle={() => handleSolutionCheck(item.id)}
                      />
                    </div>
                  </td>
                  <td>{item.solutionId}</td>
                  <td>{item.displayName}</td>
                  <td className={`${styles.cost} w-110`}>
                    {priceFormat(item.cost)}
                    {' '}
                    ₺
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <PrimaryButton
              isGhost={selectedEngines.length === 0 || solutionList.filter((e) => e.selected).length === 0}
              buttonTitle={localStr()?.vehicleConnect?.t23}
              buttonStyle="f-right m-t-24"
              buttonClick={() => connectSolutions()}
            />
          </div>
        </div>
      </div>
    </BaseView>
  );
}

export default VehicleConnect;
