import React, { useContext, useEffect, useState } from 'react';
import styles from './workingHours.module.css';
import BaseView from '../../views/baseView/baseView';
import { PrimaryButton } from '../../views/buttons/buttons';
import { HoursInput, SimpleInput, SwitchButton } from '../../views/inputs/inputs';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, workingDays } from '../../../helpers/constant';
import { MainContext } from '../../../context/store';
import { showToastError } from '../../../context/actions/toastActions';
import { getTimeObject } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function WorkingHours() {
  const { state, dispatch } = useContext(MainContext);
  const [mondayA, setMondayA] = useState(true);
  const [mondayS, setMondayS] = useState('09:55');
  const [mondayE, setMondayE] = useState('22:36');
  const [mondayI, setMondayI] = useState('');

  const [tuesdayA, setTuesdayA] = useState(true);
  const [tuesdayS, setTuesdayS] = useState('09:55');
  const [tuesdayE, setTuesdayE] = useState('22:36');
  const [tuesdatI, setTuesdayI] = useState('');

  const [wednesdayA, setWednesdayA] = useState(true);
  const [wednesdayS, setWednesdayS] = useState('09:55');
  const [wednesdayE, setWednesdayE] = useState('22:36');
  const [wednesdayI, setWednesdayI] = useState('');

  const [thursdayA, setThursdayA] = useState(true);
  const [thursdayS, setThursdayS] = useState('09:55');
  const [thursdayE, setThursdayE] = useState('22:36');
  const [thursdayI, setThursdayI] = useState('');

  const [fridayA, setFridayA] = useState(true);
  const [fridayS, setFridayS] = useState('09:55');
  const [fridayE, setFridayE] = useState('22:36');
  const [fridayI, setFridayI] = useState('');

  const [saturdayA, setSaturdayA] = useState(true);
  const [saturdayS, setSaturdayS] = useState('09:55');
  const [saturdayE, setSaturdayE] = useState('22:36');
  const [saturdayI, setSaturdayI] = useState('');

  const [sundayA, setSundayA] = useState(true);
  const [sundayS, setSundayS] = useState('09:55');
  const [sundayE, setSundayE] = useState('22:36');
  const [sundayI, setSundayI] = useState('');
  useEffect(() => {
    getData();
    eventListener();
    return () => {
      window.removeEventListener('workingHoursPage', () => { });
    };
  }, []);
  function getData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getWorkingHours,
      loading: false,
    })
      .then((res) => {
        const { data } = res;
        setData(data);
      })
      .catch(() => {});
  }
  function eventListener() {
    window.addEventListener('workingHoursPage', (event) => {
      const data = event?.detail?.data;
      if (data) {
        setData(data);
      }
    });
  }
  function updateData() {
    const timeZone = new Date().getTimezoneOffset();
    const body = {
      timeZone,
      mondayOpen: mondayS,
      mondayClose: mondayE,
      tuesdayOpen: tuesdayS,
      tuesdayClose: tuesdayE,
      wednesdayOpen: wednesdayS,
      wednesdayClose: wednesdayE,
      thursdayOpen: thursdayS,
      thursdayClose: thursdayE,
      fridayOpen: fridayS,
      fridayClose: fridayE,
      saturdayOpen: saturdayS,
      saturdayClose: saturdayE,
      sundayOpen: sundayS,
      sundayClose: sundayE,
      mondayWork: mondayA,
      tuesdayWork: tuesdayA,
      wednesdayWork: wednesdayA,
      thursdayWork: thursdayA,
      fridayWork: fridayA,
      saturdayWork: saturdayA,
      sundayWork: sundayA,
      mondayMessage: mondayI,
      tuesdayMessage: tuesdatI,
      wednesdayMessage: wednesdayI,
      thursdayMessage: thursdayI,
      fridayMessage: fridayI,
      saturdayMessage: saturdayI,
      sundayMessage: sundayI,
    };
    ServiceRequest({
      endpoint: apiEndpoints.admin.updateWorkingHours,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        const { data } = res;
        setData(data);
        const event = new CustomEvent('workingHoursHeader', { detail: { data: res?.data } });
        window.dispatchEvent(event);
      })
      .catch(() => {});
  }
  function setData(data) {
    setMondayA(data.mondayWork);
    setMondayS(data.mondayOpen);
    setMondayE(data.mondayClose);
    setMondayI(data.mondayMessage);

    setTuesdayA(data.tuesdayWork);
    setTuesdayS(data.tuesdayOpen);
    setTuesdayE(data.tuesdayClose);
    setTuesdayI(data.tuesdayMessage);

    setWednesdayA(data.wednesdayWork);
    setWednesdayS(data.wednesdayOpen);
    setWednesdayE(data.wednesdayClose);
    setWednesdayI(data.wednesdayMessage);

    setThursdayA(data.thursdayWork);
    setThursdayS(data.thursdayOpen);
    setThursdayE(data.thursdayClose);
    setThursdayI(data.thursdayMessage);

    setFridayA(data.fridayWork);
    setFridayS(data.fridayOpen);
    setFridayE(data.fridayClose);
    setFridayI(data.fridayMessage);

    setSaturdayA(data.saturdayWork);
    setSaturdayS(data.saturdayOpen);
    setSaturdayE(data.saturdayClose);
    setSaturdayI(data.saturdayMessage);

    setSundayA(data.sundayWork);
    setSundayS(data.sundayOpen);
    setSundayE(data.sundayClose);
    setSundayI(data.sundayMessage);
  }
  function checkInputs() {
    if (
      getTimeObject(mondayS) > getTimeObject(mondayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t1 }));
    } else if (
      getTimeObject(tuesdayS) > getTimeObject(tuesdayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t2 }));
    } else if (
      getTimeObject(wednesdayS) > getTimeObject(wednesdayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t3 }));
    } else if (
      getTimeObject(thursdayS) > getTimeObject(thursdayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t4 }));
    } else if (
      getTimeObject(fridayS) > getTimeObject(fridayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t5 }));
    } else if (
      getTimeObject(saturdayS) > getTimeObject(saturdayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t6 }));
    } else if (
      getTimeObject(sundayS) > getTimeObject(sundayE)
    ) {
      dispatch.toastDispatch(showToastError({ text: localStr()?.workingHours?.t7 }));
    } else {
      updateData();
    }
  }
  return (
    <BaseView path="/ Working Hours">
      <div className={styles.main}>
        <div className={styles.comp}>
          <div className={styles.TitleCont}>
            <div className="d-flex f-column f1">
              <div className={styles.t1}>{localStr()?.workingHours?.t8}</div>
              <div className={styles.t2}>
                {localStr()?.workingHours?.t9}
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t11}</div>
              <div className={styles.cont}>
                {mondayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={mondayS}
                      onChange={(e) => {
                        setMondayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={mondayE}
                      onChange={(e) => {
                        setMondayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={mondayI}
                    onChange={(e) => setMondayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={mondayA} toggle={() => setMondayA(!mondayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t12}</div>
              <div className={styles.cont}>
                {tuesdayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={tuesdayS}
                      onChange={(e) => {
                        setTuesdayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={tuesdayE}
                      onChange={(e) => {
                        setTuesdayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={tuesdatI}
                    onChange={(e) => setTuesdayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={tuesdayA} toggle={() => setTuesdayA(!tuesdayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t13}</div>
              <div className={styles.cont}>
                {wednesdayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={wednesdayS}
                      onChange={(e) => {
                        setWednesdayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={wednesdayE}
                      onChange={(e) => {
                        setWednesdayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={wednesdayI}
                    onChange={(e) => setWednesdayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={wednesdayA} toggle={() => setWednesdayA(!wednesdayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t14}</div>
              <div className={styles.cont}>
                {thursdayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={thursdayS}
                      onChange={(e) => {
                        setThursdayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={thursdayE}
                      onChange={(e) => {
                        setThursdayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={thursdayI}
                    onChange={(e) => setThursdayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={thursdayA} toggle={() => setThursdayA(!thursdayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t15}</div>
              <div className={styles.cont}>
                {fridayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={fridayS}
                      onChange={(e) => {
                        setFridayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={fridayE}
                      onChange={(e) => {
                        setFridayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={fridayI}
                    onChange={(e) => setFridayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={fridayA} toggle={() => setFridayA(!fridayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t16}</div>
              <div className={styles.cont}>
                {saturdayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={saturdayS}
                      onChange={(e) => {
                        setSaturdayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={saturdayE}
                      onChange={(e) => {
                        setSaturdayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={saturdayI}
                    onChange={(e) => setSaturdayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={saturdayA} toggle={() => setSaturdayA(!saturdayA)} />
              </div>
            </div>
          </div>
          <div className={styles.dayCont}>
            <div className="d-flex align-center justify-space-between w100">
              <div className={styles.t4}>{localStr()?.workingHours?.t17}</div>
              <div className={styles.cont}>
                {sundayA ? (
                  <div className="d-flex align-center">
                    Set
                    <HoursInput
                      formStyle="m-l-8 m-r-8"
                      value={sundayS}
                      onChange={(e) => {
                        setSundayS(e);
                      }}
                    />
                    To
                    <HoursInput
                      formStyle="m-l-8 m-r-48"
                      value={sundayE}
                      onChange={(e) => {
                        setSundayE(e);
                      }}
                    />
                  </div>
                ) : (
                  <SimpleInput
                    value={sundayI}
                    onChange={(e) => setSundayI(e)}
                    placeHolder={localStr()?.workingHours?.t10}
                    formStyle="m-r-48 w-300"
                  />
                )}
                <SwitchButton checked={sundayA} toggle={() => setSundayA(!sundayA)} />
              </div>
            </div>
          </div>
          <div>
            <PrimaryButton
              buttonTitle={localStr()?.workingHours?.t18}
              buttonStyle="f-right m-t-24"
              buttonClick={() => {
                checkInputs();
              }}
            />
          </div>
        </div>
      </div>
    </BaseView>
  );
}

export default WorkingHours;
