import React, { useEffect, useRef, useState } from 'react';
import styles from './inputs.module.css';
import eye1 from '../../../assets/images/eye1.svg';
import eye2 from '../../../assets/images/eye2.svg';
import arrow from '../../../assets/images/arrow.svg';
import checkbox from '../../../assets/images/checkbox.svg';
import { decodeString } from '../../../utils/formatter';

function GeneralInput({
  title, value, onChange = function () {}, formStyle, required, inputType = 'text', disabled = false, maxLength,
}) {
  const [focused, setFocused] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const inputRef = useRef(null);
  const getInputType = () => {
    if (inputType !== 'password') {
      return inputType;
    } if (showPass) {
      return 'text';
    }
    return 'password';
  };
  function handleTitleClick() {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }
  return (
    <div className={`${styles.content} ${focused && styles.focused} ${formStyle}`}>
      <div className="d-flex align-center">
        <input
          maxLength={maxLength}
          disabled={disabled}
          ref={inputRef}
          type={getInputType()}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`${styles.input}`}
          onFocus={(e) => setFocused(true)}
          onBlur={(e) => setFocused(false)}
        />
        <div
          onClick={() => handleTitleClick()}
          className={`${styles.title} c-text ${(focused || value !== '') && styles.focused} ${required && styles.required}`}
        >
          {title}
        </div>
        {inputType === 'password' && (
        <div className="m-r-16 c-pointer" onClick={() => setShowPass(!showPass)}>
          <img alt="eye" src={showPass ? eye1 : eye2} />
        </div>
        )}
      </div>
    </div>
  );
}

function GeneralTextArea({
  title, value, onChange = function () {}, formStyle, required, placeHolder,
}) {
  const [focused, setFocused] = useState(false);
  return (
    <div className={`${styles.content} ${focused && styles.focused} ${formStyle}`}>
      <textarea
        placeholder={placeHolder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`${styles.textArea}`}
        onFocus={(e) => setFocused(true)}
        onBlur={(e) => setFocused(false)}
      />
      <div
        className={`${styles.title} ${(focused || value !== '') && styles.focused} ${required && styles.required}`}
      >
        {title}
      </div>
    </div>
  );
}
function GeneralDropdown({
  title, value, onChange = function () {}, formStyle, required, data,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      window.addEventListener('click', (evt) => {
        setIsOpen(false);
      }, false);
    }
    currentRef.current = false;
  }, []);
  function handleClick(event) {
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 50);
  }
  return (
    <div
      className={`c-pointer ${styles.content} ${isOpen && styles.focused} ${formStyle}`}
      onClick={(event) => handleClick(event)}
    >
      <div className="d-flex align-center">
        <div
          className={`${styles.dropdownSelected}`}
        >
          {value && decodeString(value.value)}
        </div>
        <img alt="arrow" src={arrow} className="m-r-16" />
        <div
          className={`${styles.title} ${value && styles.focused} ${required && styles.required}`}
        >
          {title}
        </div>
      </div>
      {isOpen && (
        <div className={styles.dropdownItemContainer}>
          {data.map((item, index) => (
            <div
              onClick={() => onChange(item)}
              key={index}
              className={styles.dropdownItem}
            >
              {decodeString(item?.value)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
function SwitchButton({
  checked, toggle, isPassive, switchStyle,
}) {
  return (
    <div
      className={styles.switch}
      onClick={() => {
        if (!isPassive) {
          toggle();
        }
      }}
    >
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span className={`${styles.slider} ${styles.round} ${switchStyle}`} />
    </div>
  );
}
function SwitchButtonOrange({
  checked, toggle, isPassive, switchStyle,
}) {
  return (
    <div
      className={styles.switch}
      onClick={() => {
        if (!isPassive) {
          toggle();
        }
      }}
    >
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span className={`${styles.slider} ${styles.sliderOrange} ${styles.round} ${switchStyle}`} />
    </div>
  );
}
function CheckBox({
  checked, toggle, isPassive, checkboxStyle,
}) {
  return (
    <div
      className={`${styles.checkBox} ${checkboxStyle}`}
      onClick={() => {
        if (!isPassive) {
          toggle();
        }
      }}
    >
      {checked && <img src={checkbox} alt="check" />}
    </div>
  );
}
function RadioButton({ checked, toggle, isPassive }) {
  return (
    <div
      className={`${styles.radio} ${checked && styles.checked}`}
      onClick={() => {
        if (!isPassive) {
          toggle();
        }
      }}
    >
      {checked && <div />}
    </div>
  );
}
function SimpleInput({
  value, onChange = function () {}, leftIcon, leftIconStyle, formStyle, placeHolder,
}) {
  return (
    <div className={`${styles.simpleInputArea} ${formStyle}`}>
      {leftIcon && <img alt="search" src={leftIcon} className={`${styles.simpleIcon} ${leftIconStyle}`} />}
      <input
        placeholder={placeHolder}
        className={styles.simpleInput}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

function HoursInput({ value, onChange = function () {}, formStyle }) {
  return (
    <div className={`${styles.hoursCont} ${formStyle}`}>
      <input
        className={styles.hoursInput}
        value={value}
        type="time"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export {
  GeneralDropdown,
  GeneralInput,
  GeneralTextArea,
  SwitchButton,
  SimpleInput,
  CheckBox,
  RadioButton,
  SwitchButtonOrange,
  HoursInput,
};
