import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './options.module.css';
import icDelete from '../../../assets/images/Delete-1.svg';
import edit from '../../../assets/images/Edit.svg';
import plus from '../../../assets/images/Plus.svg';
import { SecondaryButton } from '../../views/buttons/buttons';
import Status from '../../views/status/status';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { showQuestion } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { priceFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function Options() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  useEffect(() => {
    getSolutionData();
  }, []);
  function getSolutionData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getSolutions,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setSolutionList(res.data);
        getData();
      })
      .catch((err) => {
      });
  }
  function getData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getTuningOptions,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
  }
  function askForDelete(optionId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.options?.t1,
        text: localStr()?.options?.t2,
        buttons: [
          { buttonTitle: localStr()?.options?.t3, buttonClick: () => deleteOption(optionId) },
          {
            buttonTitle: localStr()?.options?.t4,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteOption(optionId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteTuningOption}/${optionId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getData();
      })
      .catch((err) => {
      });
  }
  return (
    <BaseView path="/ Tuning Options">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.options?.t5}</div>
            <div className={styles.t2}>{localStr()?.options?.t6}</div>
          </div>
          <SecondaryButton
            buttonTitle={localStr()?.options?.t7}
            leftIcon={plus}
            buttonClick={() => navigate(('/addoption'))}
          />
        </div>
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="w-200">{localStr()?.options?.t8}</th>
              <th className="w-160">
                {localStr()?.options?.t9}
              </th>
              <th>{localStr()?.options?.t10}</th>
              <th className="w-132">{localStr()?.options?.t11}</th>
              <th className="text-center w-124">{localStr()?.options?.t12}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.options?.t13}
                </td>
              </tr>
            )}
            {data.map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="w-200">{item.optionName}</td>
                <td className="w-160">
                  <Status label={item.type === 'PAID' ? localStr()?.options?.t14 : localStr()?.options?.t15} type={item.type === 'PAID' ? 'completed' : 'inProgress'} />
                </td>
                <td>{solutionList.filter((e) => item.solutions.includes(e.id)).map((e) => e.solutionId).join(', ')}</td>
                <td className={`${styles.cost} w-132`}>
                  {priceFormat(item.cost)}
                  {' '}
                  ₺
                </td>
                <td className="text-center w-124">
                  <div className={styles.t41}>
                    <div className="d-flex" onClick={() => navigate('/addoption', { state: { option: item } })}>
                      <img alt="pdf" src={edit} className={`${styles.trash} m-r-12`} />
                    </div>
                    <div className={styles.line} />
                    <div className="d-flex" onClick={() => askForDelete(item.id)}>
                      <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12`} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BaseView>
  );
}

export default Options;
