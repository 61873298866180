import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styles from './fileDetail.module.css';
import BaseView from '../../views/baseView/baseView';
import { GeneralTextArea } from '../../views/inputs/inputs';
import { AlternateButton, PrimaryButton, SecondaryButton } from '../../views/buttons/buttons';
import icArrow from '../../../assets/images/downArrow1.svg';
import icArrow1 from '../../../assets/images/arrow-blue-right.svg';
import icDownload from '../../../assets/images/download-simple.svg';
import icDownloadGreen from '../../../assets/images/downloadGreen.svg';
import icDownloadBlue from '../../../assets/images/download-blue.svg';
import icInvoiceUpload from '../../../assets/images/upload-blue.svg';
import icUpload2 from '../../../assets/images/upload-simple-w.svg';
import person from '../../../assets/images/defaultProfileImage.png';
import icDelete from '../../../assets/images/Delete-1.svg';
import Status, { GetStatusLabel } from '../../views/status/status';
import { ServiceRequest } from '../../../helpers/connectionManager';
import {
  apiEndpoints, messageSenderEnums, orderStatusEnums, statusLabel, storageKeys,
} from '../../../helpers/constant';
import { decodeString, priceFormat } from '../../../utils/formatter';
import { showQuestion } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { GeneralModal } from '../../views/modals/generalModal';
import { zipFile } from '../../../utils/helperFunctions';
import { showToastError } from '../../../context/actions/toastActions';
import icUploadFile from '../../../assets/images/upload-simple.svg';
import icBin from '../../../assets/images/ic-file-upload.png';
import { getSessionValue } from '../../../helpers/sessionManager';
import { localStr } from '../../../helpers/language';

function FileDetail() {
  const { dispatch } = useContext(MainContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedFile = state?.file;
  if (!selectedFile) {
    return <Navigate to="/" />;
  }
  const [file, setFile] = useState(selectedFile);
  const [statusModal, setStatusModal] = useState(false);
  const [editorModal, setEditorModal] = useState(false);
  const [supportMessage, setSupportMessage] = useState('');
  const [supportFile, setSupportFile] = useState(null);
  const [chatData, setChatData] = useState(null);
  let interval;
  useEffect(() => {
    getOrderDetail(true);
    checkOrderChatMessage();
    getOrderChatMessages();
    return () => {
      clearInterval(interval);
    };
  }, []);
  const currentRef = useRef(true);
  useEffect(() => {
    const dropZone = document.getElementById('uploadDragArea');
    if (currentRef.current) {
      document.addEventListener('dragover', (e) => {
        e.preventDefault();
      });
      dropZone.addEventListener('drop', (e) => {
        handleSupportFile(e.dataTransfer.files[0]);
        e.preventDefault();
      });
    }
    currentRef.current = false;
    return () => {
      document.removeEventListener('dragover', (e) => {});
      dropZone.removeEventListener('drop', (e) => {});
    };
  }, []);

  function checkOrderChatMessage() {
    interval = setInterval(() => {
      getOrderChatMessages();
      getOrderDetail(false);
    }, 15000);
  }

  function getOrderChatMessages() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getOrderChatMessages}/${file?.id}`,
      loading: false,
    })
      .then((res) => {
        setChatData(res.data);
      })
      .catch((error) => { });
  }
  function getOrderDetail(loading) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getOrderDetailForAdmin}/${file?.id}`,
      loading,
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((error) => { });
  }
  function downloadOriginalFile() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadOriginalFile}/${file?.id}`,
      loading: true,
      downloadFile: 'original-file',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function downloadEcuFile() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadEcuFile}/${file?.id}`,
      loading: true,
      downloadFile: 'mod-file',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function askForDeleteEcuFile() {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.fileDetail?.t1,
        text: localStr()?.fileDetail?.t2,
        buttons: [
          { buttonTitle: localStr()?.fileDetail?.t3, buttonClick: () => deleteEcuFile() },
          {
            buttonTitle: localStr()?.fileDetail?.t4,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteEcuFile() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteEcuFile}/${file?.id}`,
      loading: true,
      successMessage: true,
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((error) => { });
  }

  function handleFileStatusChange(status) {
    setStatusModal(false);
    const body = {
      orderStatus: status,
    };
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.updateOrderStatusForAdmin}/${file?.id}`,
      loading: true,
      method: 'POST',
      successMessage: true,
      body,
    })
      .then((res) => {
        setFile(res?.data);
      })
      .catch((error) => { });
  }

  function toggleEditorStatus() {
    setEditorModal(false);
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.updateEditorStatusForAdmin}/${file?.id}`,
      loading: true,
      method: 'GET',
      successMessage: true,
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((error) => { });
  }

  const handleEcuFile = (ecuFile) => {
    zipFile(ecuFile)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadEcuFile}/${file.id}`,
          loading: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            setFile(res.data);
            const input = document.getElementById('uploadEcuFile');
            if (input) {
              input.value = null;
            }
          })
          .catch((error) => {
            const input = document.getElementById('uploadEcuFile');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.fileDetail?.t5 }));
      });
  };
  function handleBrowseFile(inputId) {
    const input = document.getElementById(inputId);
    if (input) {
      input.click();
    }
  }
  function handleNavigateUserDetail() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserDetailForAdmin}/${file?.user?.id}`,
      loading: true,
    })
      .then((res) => {
        navigate('/userdetail', { state: { user: res?.data } });
      })
      .catch((error) => { });
  }
  function downloadInvoice() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadInvoice}/${file.id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function askForDeleteInvoice() {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.fileDetail?.t6,
        text: localStr()?.fileDetail?.t7,
        buttons: [
          { buttonTitle: localStr()?.fileDetail?.t8, buttonClick: () => deleteInvoice() },
          {
            buttonTitle: localStr()?.fileDetail?.t9,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteInvoice() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteInvoice}/${file.id}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((err) => {
      });
  }
  const handleInvoiceFile = (invoiceFile) => {
    zipFile(invoiceFile)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadInvoice}/${file.id}`,
          loading: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            setFile(res.data);
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
          })
          .catch((error) => {
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.fileDetail?.t10 }));
      });
  };
  const handleSupportFile = (invoiceFile) => {
    zipFile(invoiceFile)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadChatFile}/${file.id}`,
          loading: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            setSupportFile(res.data);
            const input = document.getElementById('uploadSupportFile');
            if (input) {
              input.value = null;
            }
          })
          .catch((error) => {
            const input = document.getElementById('uploadSupportFile');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.fileDetail?.t10 }));
      });
  };

  function sendOrderChatMessage() {
    const body = {
      file: supportFile,
      text: supportMessage,
    };
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.sendOrderChatMessage}/${file?.id}`,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        setChatData(res.data);
        setSupportFile(null);
        setSupportMessage('');
      })
      .catch((error) => { });
  }
  function downloadChatAttachment(fileName) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadOrderChatFile}/${fileName}/${file.id}`,
      loading: true,
      downloadFile: 'chat',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function getMessagePhoto(message) {
    switch (message.messageSender) {
      case messageSenderEnums.customer: {
        return chatData.user?.logo ? `data:image/png;base64,${chatData.user?.logo}` : person;
      }
      case messageSenderEnums.admin: {
        const credentials = getSessionValue({ key: storageKeys.credentials });
        return credentials?.logoWhite ? `data:image/png;base64,${credentials?.logoWhite}` : person;
      }
      default:
        return person;
    }
  }
  return (
    <BaseView path="/ Files / File Detail">
      <input
        type="file"
        id="uploadEcuFile"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleEcuFile(event.target.files[0])}
      />
      <input
        type="file"
        id="uploadInvoice"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleInvoiceFile(event.target.files[0])}
      />
      <input
        type="file"
        id="uploadSupportFile"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleSupportFile(event.target.files[0])}
      />
      <GeneralModal toggle={() => setStatusModal(false)} visible={statusModal}>
        <div className="p-32 d-flex f-column">
          <div className={styles.t1}>
            {localStr()?.fileDetail?.t11}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleFileStatusChange('COMPLETED')}>
            {GetStatusLabel({ type: 'completed', labelList: statusLabel() })}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleFileStatusChange('INPROGRESS')}>
            {GetStatusLabel({ type: 'inProgress', labelList: statusLabel() })}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleFileStatusChange('PROCESSING')}>
            {GetStatusLabel({ type: 'processing', labelList: statusLabel() })}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleFileStatusChange('CANCELED')}>
            {GetStatusLabel({ type: 'canceled', labelList: statusLabel() })}
          </div>
        </div>
      </GeneralModal>
      <GeneralModal toggle={() => setEditorModal(false)} visible={editorModal}>
        <div className="p-32 d-flex f-column">
          <div className={styles.t1}>
            {localStr()?.fileDetail?.t49}
          </div>
          {file?.editor
            ? (
              <div className="m-t-16 c-pointer" onClick={() => toggleEditorStatus()}>
                {GetStatusLabel({ type: 'inProgress', labelList: ['', localStr()?.fileDetail?.t51] })}
              </div>
            ) : (
              <div className="m-t-16 c-pointer" onClick={() => toggleEditorStatus()}>
                {GetStatusLabel({ type: 'completed', labelList: [localStr()?.fileDetail?.t50] })}
              </div>
            )}
          <div className="m-t-16 c-pointer" onClick={() => setEditorModal(false)}>
            {GetStatusLabel({ type: 'canceled', labelList: ['', '', '', localStr()?.fileDetail?.t52] })}
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex align-start">
          <div className={styles.cont1}>
            <div className={styles.titleCont}>
              <div className={styles.t1}>{localStr()?.fileDetail?.t12}</div>
              <div className={styles.t2}>
                {localStr()?.fileDetail?.t13}
              </div>
            </div>
            <div id="uploadDragArea" className={styles.c1}>
              {supportFile ? (
                <div className="d-flex f-column align-center">
                  <img alt="file" src={icBin} />
                  <SecondaryButton
                    buttonTitle={localStr()?.fileDetail?.t14}
                    buttonStyle="w-100 h-12 m-t-12"
                    buttonClick={() => setSupportFile(null)}
                  />
                </div>
              ) : (
                <>
                  <img alt="upload" src={icUploadFile} className={styles.icUpload} />
                  <div className="m-t-16">
                    {localStr()?.fileDetail?.t15}
                    {' '}
                    <span onClick={() => handleBrowseFile('uploadSupportFile')}>{localStr()?.fileDetail?.t16}</span>
                    {' '}
                    {localStr()?.fileDetail?.t17}
                  </div>
                </>
              )}
            </div>
            <GeneralTextArea
              value={supportMessage}
              onChange={(e) => setSupportMessage(e)}
              formStyle="m-t-24"
              placeHolder={localStr()?.fileDetail?.t18}
            />
            <PrimaryButton isGhost={supportMessage.length < 3} buttonClick={() => sendOrderChatMessage()} buttonTitle={localStr()?.fileDetail?.t19} buttonStyle="m-t-24 w-132 align-self-end" />
          </div>
          <div className={styles.cont2}>
            <div className={styles.titleCont1}>
              <div className="d-flex f-column f1">
                <div
                  className={styles.t1}
                >
                  {decodeString(`${file?.brandName} / ${file?.modelName} / ${file?.generationName} / ${file?.engineName}`)}
                </div>
                <div className={styles.t3}>{file?.solution?.displayName}</div>
              </div>
            </div>
            <div className="m-t-12">
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t48}</div>
                <div className="d-flex c-pointer">
                  <Status
                    label={file?.editor?.fullName}
                    onClick={() => setEditorModal(true)}
                    type={file?.editor ? orderStatusEnums.COMPLETED : orderStatusEnums.INPROGRESS}
                  />
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t20}</div>
                <div className={styles.t10} onClick={() => handleNavigateUserDetail()}>
                  {file?.user?.fullName}
                  <img alt="arrow" src={icArrow1} className="m-l-4" />
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t21}</div>
                <div className="d-flex c-pointer">
                  <Status onClick={() => setStatusModal(true)} type={orderStatusEnums[file.orderStatus]} />
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t22}</div>
                {file.invoice ? (
                  <div className={styles.t41}>
                    <div className="d-flex c-pointer align-center" onClick={() => downloadInvoice()}>
                      {file.invoice}
                      <img alt="pdf" src={icDownloadBlue} className="m-l-8 m-r-12" />
                    </div>
                    <div className={styles.line} />
                    <div className="d-flex c-pointer" onClick={() => askForDeleteInvoice()}>
                      <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12`} />
                    </div>
                  </div>
                )
                  : (
                    <div className={styles.t41}>
                      <div className={styles.t42}>{localStr()?.fileDetail?.t23}</div>
                      <div className="d-flex c-pointer" onClick={() => handleBrowseFile('uploadInvoice')}>
                        <img alt="pdf" src={icInvoiceUpload} className={`${styles.icUpload1} m-l-8`} />
                      </div>
                    </div>
                  )}
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t24}</div>
                <div className={styles.t4}>
                  {decodeString(file?.brandName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t25}</div>
                <div className={styles.t4}>
                  {decodeString(file?.modelName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t26}</div>
                <div className={styles.t4}>
                  {decodeString(file?.generationName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t27}</div>
                <div className={styles.t4}>
                  {decodeString(file?.engineName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t28}</div>
                <div className={styles.t4}>
                  {file?.plate}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t29}</div>
                <div className={styles.t4}>
                  {file?.transmission}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t30}</div>
                <div className={styles.t4}>
                  {file?.toolType}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t31}</div>
                <div className={styles.t4}>
                  {file?.fileType}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t32}</div>
                <div className={styles.t4}>
                  {file?.tool}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t33}</div>
                <div className={styles.t4}>
                  {file?.ecuType}
                </div>
              </div>
              {(file?.orderNote !== null && file?.orderNote !== '') && (
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t34}</div>
                <div className={styles.t4}>
                  {file?.orderNote}
                </div>
              </div>
              )}
              {file?.tuningOptions?.length > 0 && (
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t35}</div>
                <div className={styles.t4}>
                  {file?.tuningOptions.map((e) => e.optionName).join(',')}
                </div>
              </div>
              )}
              {file?.master && (
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t36}</div>
                <div className={styles.t4}>
                  {file?.master?.fullName}
                </div>
              </div>
              )}
              {file?.coupon
                  && (
                  <div className={styles.c2}>
                    <div>{localStr()?.fileDetail?.t37}</div>
                    <div className={styles.t4}>
                      %
                      {file?.coupon?.rate}
                    </div>
                  </div>
                  )}
            </div>
            <div className={styles.c3}>
              <div className={styles.t5}>{localStr()?.fileDetail?.t38}</div>
              <div className={styles.t6}>
                {priceFormat(file?.amount)}
                {' '}
                ₺
              </div>
            </div>
            {file.modifiedFile && (
            <div className={styles.c3}>
              <div className="d-flex align-center">
                <div className={styles.t11}>
                  {localStr()?.fileDetail?.t39}
                  {' '}
                  <span>{new Date(file?.modifiedFileUploadDate).toLocaleString()}</span>
                </div>
              </div>
              <div className={styles.t61}>
                <div className="d-flex align-center">
                  <div className="c-pointer d-flex align-center" onClick={() => downloadEcuFile()}>
                    {file.modifiedFile}
                    <img alt="pdf" src={icDownloadGreen} className="m-l-8 m-r-12" />
                  </div>
                  <div className={styles.line} />
                  <div className="d-flex c-pointer" onClick={() => askForDeleteEcuFile()}>
                    <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12`} />
                  </div>
                </div>
              </div>
            </div>
            )}
            <div className="d-flex justify-space-between m-t-24">
              <AlternateButton
                buttonClick={() => downloadOriginalFile()}
                buttonTitle={localStr()?.fileDetail?.t40}
                leftIcon={icDownload}
                leftIconStyle={styles.icDownload}
              />
              <PrimaryButton
                buttonClick={() => handleBrowseFile('uploadEcuFile')}
                buttonTitle={file.modifiedFile ? localStr()?.fileDetail?.t41 : localStr()?.fileDetail?.t42}
                leftIcon={icUpload2}
                leftIconStyle={styles.icDownload}
              />
            </div>
          </div>
        </div>
        {chatData?.messages && (
        <div className={styles.cont3}>
          <div className={styles.titleCont}>
            <div className={styles.t1}>{localStr()?.fileDetail?.t43}</div>
            <div className={styles.t2}>
              {localStr()?.fileDetail?.t44}
            </div>
          </div>
          <div className={styles.chatArea}>
            {[...chatData.messages].reverse().map((item, index) => (
              <div key={index} className={styles.c4}>
                <div className="d-flex f-column">
                  <img alt="profile" src={getMessagePhoto(item)} className={item.messageSender === messageSenderEnums.customer ? styles.messagePhoto : styles.messagePhotoAdmin} />
                  {chatData.messages.length - 1 !== index && <div className={styles.chatLine} />}
                </div>
                <div className={styles.c5}>
                  <div className={styles.c6}>
                    <div className={styles.t7}>{item.senderFullName}</div>
                    <div className={`${styles.t8} m-l-12`}>{new Date(item.messageDate).toLocaleString()}</div>
                  </div>
                  <div className={styles.t9}>
                    <div>{item.text}</div>
                    {item.file && (
                    <SecondaryButton
                      buttonTitle={localStr()?.fileDetail?.t45}
                      buttonStyle="m-t-16 w-150"
                      leftIcon={icArrow}
                      leftIconStyle={styles.icDownload}
                      buttonClick={() => downloadChatAttachment(item.file)}
                    />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default FileDetail;
