import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './forgotPassword.module.css';
import poster from '../../../assets/images/loginBackPoster.png';
import back from '../../../assets/login.mp4';
import { PrimaryButton } from '../../views/buttons/buttons';
import { GeneralInput } from '../../views/inputs/inputs';
import close from '../../../assets/images/x-light.svg';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, storageKeys } from '../../../helpers/constant';
import { getSessionValue } from '../../../helpers/sessionManager';
import { localStr } from '../../../helpers/language';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [merchantCredentials, setMerchantCredentials] = useState(null);
  useEffect(() => {
    getCredentials();
  }, []);
  function getCredentials() {
    const credentials = getSessionValue({ key: storageKeys.credentials });
    setMerchantCredentials(credentials);
  }
  function navigateLink(link) {
    window.open(link);
  }
  function forgotPassword() {
    const body = {
      email,
    };
    ServiceRequest({
      endpoint: apiEndpoints.auth.forgotPassword,
      loading: true,
      method: 'POST',
      body,
      successMessage: true,
    })
      .then((res) => {
        navigate('/checkmail', { state: { email } });
      })
      .catch((error) => { });
  }
  return (
    <div className={styles.main}>
      <div className={styles.cont1}>
        <video autoPlay loop muted className={styles.back} poster={poster}>
          <source src={back} type="video/mp4" />
        </video>
        {merchantCredentials && (
        <div className={styles.videoLayer}>
          <img alt="ecufile" src={`data:image/png;base64,${merchantCredentials?.logoBlack}`} className={styles.logo} />
          <div className="d-flex align-center m-b-72 justify-center">
            {merchantCredentials?.infoMail && (
            <div onClick={() => navigateLink(`mailto:${merchantCredentials?.infoMail}?subject=Information`)} className={styles.footerItem}>{merchantCredentials?.infoMail}</div>
            )}
            {merchantCredentials?.facebook && (
            <div onClick={() => navigateLink(merchantCredentials?.facebook)} className={styles.footerItem}>facebook</div>
            )}
            {merchantCredentials?.instagram && (
            <div onClick={() => navigateLink(merchantCredentials?.instagram)} className={styles.footerItem}>instagram</div>
            )}
            {merchantCredentials?.linkedin && (
            <div onClick={() => navigateLink(merchantCredentials?.linkedin)} className={styles.footerItem}>linkedin</div>
            )}
            {merchantCredentials?.youtube && (
            <div onClick={() => navigateLink(merchantCredentials?.youtube)} className={styles.footerItem}>youtube</div>
            )}
            {merchantCredentials?.whatsapp && (
            <div onClick={() => navigateLink(merchantCredentials?.whatsapp)} className={styles.footerItem}>whatsapp</div>
            )}
            {merchantCredentials?.website && (
            <div onClick={() => navigateLink(merchantCredentials?.website)} className={styles.footerItem}>{merchantCredentials?.website}</div>
            )}
          </div>
        </div>
        )}
      </div>
      <div className={styles.cont2}>
        <div className="d-flex f-column align-end">
          <div className={styles.close} onClick={() => navigate(-1)}>
            <img alt="close" src={close} />
          </div>
        </div>
        <div>
          <div className={styles.title}>{localStr()?.forgotPassword?.t1}</div>
          <div className={`${styles.t3} m-t-16`}>{localStr()?.forgotPassword?.t2}</div>
          <GeneralInput value={email} onChange={(e) => setEmail(e)} title={localStr()?.forgotPassword?.t3} formStyle="m-t-48" required />
          <PrimaryButton buttonTitle={localStr()?.forgotPassword?.t4} buttonStyle="m-t-48" buttonClick={() => forgotPassword()} />
        </div>
        <div>
          <div className={`${styles.t3} m-t-16`}>
            <span onClick={() => { window.open('/agreement'); }}>{localStr()?.forgotPassword?.t5}</span>
            {' '}
            {localStr()?.forgotPassword?.t6}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
