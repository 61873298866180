import React, { useContext, useEffect, useState } from 'react';
import styles from './coupon.module.css';
import BaseView from '../../views/baseView/baseView';
import { PrimaryButton, SecondaryButton } from '../../views/buttons/buttons';
import plus from '../../../assets/images/Plus.svg';
import edit from '../../../assets/images/copy-simple.svg';
import copy from '../../../assets/images/copy-simple-2.svg';
import ticket from '../../../assets/images/ticket.svg';
import icDelete from '../../../assets/images/Delete-1.svg';
import Status from '../../views/status/status';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralInput } from '../../views/inputs/inputs';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { onlyNumberFormat } from '../../../utils/formatter';
import { copyContent } from '../../../utils/helperFunctions';
import { MainContext } from '../../../context/store';
import { showQuestion } from '../../../context/actions/popUpActions';
import { localStr } from '../../../helpers/language';

function Coupon() {
  const { state, dispatch } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [generatedCode, setGeneratedCode] = useState(null);
  const [rate, setRate] = useState('');
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getCoupons,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
  }
  function generateCoupon() {
    const body = { rate };
    ServiceRequest({
      endpoint: apiEndpoints.admin.createCoupon,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        setGeneratedCode(res?.data?.code);
        getData();
      })
      .catch((err) => {
      });
  }
  function askForDelete(couponId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.coupon?.t1,
        text: localStr()?.coupon?.t2,
        buttons: [
          { buttonTitle: localStr()?.coupon?.t3, buttonClick: () => deleteCoupon(couponId) },
          {
            buttonTitle: localStr()?.coupon?.t4,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteCoupon(couponId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteCoupon}/${couponId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getData();
      })
      .catch((err) => {
      });
  }
  return (
    <BaseView path="/ Tuning Solutions">
      <GeneralModal
        toggle={() => {
          setModal(false);
          setGeneratedCode(null);
          setRate('');
        }}
        visible={modal}
      >
        <div className={styles.ticketModal}>
          <img alt="ticket" src={ticket} />
          <div className={styles.modalT1}>
            {localStr()?.coupon?.t5}
          </div>
          <div className={styles.modalT2}>
            {localStr()?.coupon?.t6}
          </div>
          <GeneralInput
            title={localStr()?.coupon?.t7}
            required
            disabled={generatedCode}
            formStyle={styles.modalInput}
            value={rate}
            onChange={(e) => {
              const r = onlyNumberFormat(e);
              if (r <= 100) {
                setRate(r);
              }
            }}
          />
          {generatedCode && (
          <div className={styles.modalMid} onClick={() => copyContent({ text: generatedCode })}>
            {generatedCode}
            <img alt="copy" src={copy} />
          </div>
          )}
          {generatedCode ? (
            <div className="d-flex align-center m-t-32">
              <SecondaryButton
                buttonTitle={localStr()?.coupon?.t8}
                buttonStyle={`${styles.modalBtn}`}
                buttonClick={() => {
                  setModal(false);
                  setGeneratedCode(null);
                  setRate('');
                }}
              />
            </div>
          ) : (
            <div className="d-flex align-center m-t-32">
              <PrimaryButton
                isGhost={rate === ''}
                buttonTitle={localStr()?.coupon?.t9}
                buttonStyle={`${styles.modalBtn}`}
                buttonClick={() => generateCoupon()}
              />
            </div>
          )}
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.coupon?.t10}</div>
            <div className={styles.t2}>{localStr()?.coupon?.t11}</div>
          </div>
          <SecondaryButton
            buttonTitle={localStr()?.coupon?.t12}
            leftIcon={plus}
            buttonClick={() => {
              setModal(true);
            }}
          />
        </div>
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th>{localStr()?.coupon?.t13}</th>
              <th>{localStr()?.coupon?.t14}</th>
              <th className="w-180">
                {localStr()?.coupon?.t15}
              </th>
              <th className="text-center w-180">{localStr()?.coupon?.t16}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
            <tr>
              <td colSpan={7} className={styles.tableEmpty}>
                {localStr()?.coupon?.t17}
              </td>
            </tr>
            )}
            {data.map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td>{item.code}</td>
                <td>
                  {item.rate}
                  %
                </td>
                <td className={`${styles.cost} w-180`}>
                  <div className="d-flex f-column w100 align-center">
                    {item.used ? <Status type="canceled" label={localStr()?.coupon?.t18} /> : <Status type="success" label={localStr()?.coupon?.t19} />}
                  </div>
                </td>
                <td className="text-center w-180">
                  <div className={styles.t41}>
                    <div className="d-flex" onClick={() => copyContent({ text: item.code })}>
                      <img alt="pdf" src={edit} className={`${styles.trash} m-r-12`} />
                    </div>
                    <div className={styles.line} />
                    <div className="d-flex" onClick={() => askForDelete(item.id)}>
                      <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12`} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BaseView>
  );
}

export default Coupon;
