import React, { useEffect, useState } from 'react';
import styles from './dtcSearch.module.css';
import BaseView from '../../views/baseView/baseView';
import { SimpleInput } from '../../views/inputs/inputs';
import search from '../../../assets/images/search.svg';
import { VehicleServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import Pagination from '../../views/pagination/pagination';
import { localStr } from '../../../helpers/language';

function DtcSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 500;
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.ecuData.getAllDtc,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => {
      });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      allData = allData.filter((e) => String(e.code).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(e.description).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      setFilteredData(allData);
    }
  }, [searchTerm]);

  return (
    <BaseView path="/ DTC Search">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.dtc?.t1}</div>
            <div className={styles.t2}>{localStr()?.dtc?.t2}</div>
          </div>
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.dtc?.t3}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="w-132">{localStr()?.dtc?.t4}</th>
              <th>{localStr()?.dtc?.t5}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.dtc?.t6}
                </td>
              </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="w-132">{item.code}</td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
          <div className="d-flex m-t-24 align-center">
            <Pagination
              formStyle={styles.pagination}
              selectedPage={selectedPage}
              totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
              onChange={(e) => setSelectedPage(e)}
            />
            {searchTerm === '' && (
            <div className={styles.t3}>
              {localStr()?.dtc?.t7}
              {' '}
              {(selectedPage - 1) * pageItemCount}
              {' '}
              -
              {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
              {' '}
              {localStr()?.dtc?.t8}
              {' '}
              {filteredData.length}
            </div>
            )}
          </div>
        )}
      </div>
    </BaseView>
  );
}

export default DtcSearch;
