import React, { useEffect, useState } from 'react';
import styles from './vehicleList.module.css';
import { CheckBox, SimpleInput } from '../../views/inputs/inputs';
import search from '../../../assets/images/search.svg';
import { decodeString } from '../../../utils/formatter';
import Pagination from '../../views/pagination/pagination';

function VehicleList({
  title, searchPlaceHolder, list, formStyle, noDataMessage, dataChange, type, listKey,
}) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 1000;
  function handleTableItemCheckbox({ itemId }) {
    const newData = JSON.parse(JSON.stringify(data));
    const newFilteredData = JSON.parse(JSON.stringify(filteredData));
    const index1 = newData.findIndex((e) => e.id === itemId);
    const index2 = newFilteredData.findIndex((e) => e.id === itemId);
    if (index1 > -1 && index2 > -1) {
      newData[index1].selected = !newData[index1].selected;
      newFilteredData[index2].selected = !newFilteredData[index2].selected;
      setData(newData);
      setFilteredData(newFilteredData);
      handleDataChange(newData);
    }
  }

  useEffect(() => {
    if (list) {
      setSearchTerm('');
      setSelectedPage(1);
      setData([...list]);
      setFilteredData([...list]);
    }
  }, [list]);

  function toggleAllCheck() {
    const checked = filteredData.filter((e) => !e.selected).length === 0;
    const newData = JSON.parse(JSON.stringify(data));
    const newFilteredData = JSON.parse(JSON.stringify(filteredData));
    const resultData = newData.map((e) => ({ ...e, selected: !checked }));
    setData(resultData);
    setFilteredData(newFilteredData.map((e) => ({ ...e, selected: !checked })));
    handleDataChange(resultData);
  }
  function handleDataChange(currentData) {
    const selectedList = currentData.filter((e) => e.selected).map((e) => String(e.id));
    dataChange(selectedList);
  }

  useEffect(() => {
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      allData = allData.filter((e) => String(type === 'fullName' ? e.fullName : e.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      setFilteredData(allData);
    }
  }, [searchTerm]);
  return (
    <div className={`${styles.selectionCont} ${formStyle}`}>
      <div className={styles.selectionTitle}>{title}</div>
      <div className={styles.selectionCont1}>
        <SimpleInput
          placeHolder={searchPlaceHolder}
          leftIcon={search}
          formStyle="bg-white"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e)}
        />
        {filteredData.length > 0 ? (
          <div className={styles.selectionList}>
            <div className={`${styles.selectionRow} justify-space-between`}>
              {searchTerm === '' && (
              <div className="d-flex align-center">
                <CheckBox
                  checked={filteredData.filter((e) => !e.selected).length === 0}
                  toggle={() => toggleAllCheck()}
                />
                All
              </div>
              )}
            </div>
            <div className={styles.selectionArea}>
              {type === 'fullName' ? filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map(((item, index) => (
                <div key={index} className={styles.selectionRow}>
                  <CheckBox checked={item.selected} toggle={() => handleTableItemCheckbox({ itemId: item.id })} />
                  {decodeString(item.fullName)}
                </div>
              ))) : filteredData.map(((item, index) => (
                <div key={index} className={styles.selectionRow}>
                  <CheckBox checked={item.selected} toggle={() => handleTableItemCheckbox({ itemId: item.id })} />
                  {decodeString(item.name)}
                </div>
              )))}
            </div>
          </div>
        ) : (
          <div className={styles.selectionList2}>
            {searchTerm === '' ? noDataMessage : ''}
          </div>
        )}
      </div>
      {listKey === 'engine' && filteredData.length > 0 && (
      <div className="d-flex m-t-24 align-center">
        <Pagination
          formStyle={styles.pagination}
          selectedPage={selectedPage}
          totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
          onChange={(e) => setSelectedPage(e)}
        />
        {searchTerm === '' && (
        <div className={styles.t3}>
          Showing
          {' '}
          {(selectedPage - 1) * pageItemCount}
          {' '}
          -
          {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
          {' '}
          of
          {' '}
          {filteredData.length}
        </div>
        )}
      </div>
      )}
    </div>
  );
}

export default VehicleList;
