import {
  ERROR_TOAST_ON, INFO_TOAST_ON, QUESTION_TOAST_ON, SUCCESS_TOAST_ON,
} from '../types';

export const showToastError = ({
  text = 'Something went wrong.',
}) => ({
  type: ERROR_TOAST_ON,
  text,
});

export const showToastInfo = ({
  text = 'Done.',
}) => ({
  type: INFO_TOAST_ON,
  text,
});

export const showToastQuestion = ({
  text = 'Done.',
}) => ({
  type: QUESTION_TOAST_ON,
  text,
});

export const showToastSuccess = ({
  text = 'Done.',
}) => ({
  type: SUCCESS_TOAST_ON,
  text,
});
