import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './checkMail.module.css';
import poster from '../../../assets/images/loginBackPoster.png';
import back from '../../../assets/login.mp4';
import close from '../../../assets/images/x-light.svg';
import otpBack from '../../../assets/images/checkMail.svg';
import { getSessionValue } from '../../../helpers/sessionManager';
import { storageKeys } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';

function CheckMail() {
  const { state } = useLocation();
  const { email } = state || {};
  const [merchantCredentials, setMerchantCredentials] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getCredentials();
  }, []);
  function getCredentials() {
    const credentials = getSessionValue({ key: storageKeys.credentials });
    setMerchantCredentials(credentials);
  }
  function navigateLink(link) {
    window.open(link);
  }
  return (
    <div className={styles.main}>
      <div className={styles.cont1}>
        <video autoPlay loop muted className={styles.back} poster={poster}>
          <source src={back} type="video/mp4" />
        </video>
        {merchantCredentials && (
        <div className={styles.videoLayer}>
          <img alt="ecufile" src={`data:image/png;base64,${merchantCredentials?.logoBlack}`} className={styles.logo} />
          <div className="d-flex align-center m-b-72 justify-center">
            {merchantCredentials?.infoMail && (
            <div onClick={() => navigateLink(`mailto:${merchantCredentials?.infoMail}?subject=Information`)} className={styles.footerItem}>{merchantCredentials?.infoMail}</div>
            )}
            {merchantCredentials?.facebook && (
            <div onClick={() => navigateLink(merchantCredentials?.facebook)} className={styles.footerItem}>facebook</div>
            )}
            {merchantCredentials?.instagram && (
            <div onClick={() => navigateLink(merchantCredentials?.instagram)} className={styles.footerItem}>instagram</div>
            )}
            {merchantCredentials?.linkedin && (
            <div onClick={() => navigateLink(merchantCredentials?.linkedin)} className={styles.footerItem}>linkedin</div>
            )}
            {merchantCredentials?.youtube && (
            <div onClick={() => navigateLink(merchantCredentials?.youtube)} className={styles.footerItem}>youtube</div>
            )}
            {merchantCredentials?.whatsapp && (
            <div onClick={() => navigateLink(merchantCredentials?.whatsapp)} className={styles.footerItem}>whatsapp</div>
            )}
            {merchantCredentials?.website && (
            <div onClick={() => navigateLink(merchantCredentials?.website)} className={styles.footerItem}>{merchantCredentials?.website}</div>
            )}
          </div>
        </div>
        )}
      </div>
      <div className={styles.cont2}>
        <div className={styles.close} onClick={() => navigate('/login')}>
          <img alt="close" src={close} />
        </div>
        <div className="d-flex f-column align-center f1 justify-center">
          <img alt="otp" src={otpBack} />
          <div className={styles.t1}>{localStr()?.checkMail?.t1}</div>
          <div className={styles.t2}>
            {localStr()?.checkMail?.t2}
            {' '}
            <span>
              “
              {email}
              ”
            </span>
            {' '}
            {localStr()?.checkMail?.t3}
          </div>
        </div>
        <div>
          <div className={`${styles.t3} m-t-16`}>
            <span onClick={() => { window.open('/agreement'); }}>{localStr()?.checkMail?.t4}</span>
            {' '}
            {localStr()?.checkMail?.t5}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckMail;
