const storageKeys = {
  token: 'e_f_a_t',
  refreshToken: 'e_f_a_r_t',
  userId: 'e_f_a_u_i',
  user: 'e_f_a_u',
  credentials: 'e_f_a_c',
  langCode: 'e_f_u_l',
};

const weekDay = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      'Pazar',
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Parşembe',
      'Cuma',
      'Cumartesi',
    ];
  }
  return [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
};
const workingDays = [
  'sundayWork',
  'mondayWork',
  'tuesdayWork',
  'wednesdayWork',
  'thursdayWork',
  'fridayWork',
  'saturdayWork',
];
const workingDaysOpen = [
  'sundayOpen',
  'mondayOpen',
  'tuesdayOpen',
  'wednesdayOpen',
  'thursdayOpen',
  'fridayOpen',
  'saturdayOpen',
];
const workingDaysClose = [
  'sundayClose',
  'mondayClose',
  'tuesdayClose',
  'wednesdayClose',
  'thursdayClose',
  'fridayClose',
  'saturdayClose',
];
const billingTypes = {
  company: 'COMPANY',
  individual: 'INDIVIDUAL',
};
const currencyTypes = {
  TRY: 'TL (₺)',
  EUR: 'Euro (€)',
  USD: 'Usd ($)',
};
const optionTypeList = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      {
        key: 'PAID',
        value: 'ÜCRETLİ',
      },
      {
        key: 'FREE',
        value: 'ÜCRETSİZ',
      },
    ];
  }
  return [
    {
      key: 'PAID',
      value: 'PAID',
    },
    {
      key: 'FREE',
      value: 'FREE',
    },
  ];
};
const optionTypeEnums = {
  paid: 'PAID',
  free: 'FREE',
};

const orderStatusList = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      {
        key: 'all',
        value: 'TÜMÜ',
      },
      {
        key: 'COMPLETED',
        value: 'TAMAMLANDI',
      },
      {
        key: 'INPROGRESS',
        value: 'BEKLENİYOR',
      },
      {
        key: 'PROCESSING',
        value: 'DÜZENLENİYOR',
      },
      {
        key: 'CANCELED',
        value: 'İPTAL',
      },
    ];
  }

  return [
    {
      key: 'all',
      value: 'ALL',
    },
    {
      key: 'COMPLETED',
      value: 'COMPLETED',
    },
    {
      key: 'INPROGRESS',
      value: 'IN PROGRESS',
    },
    {
      key: 'PROCESSING',
      value: 'PROCESSING',
    },
    {
      key: 'CANCELED',
      value: 'CANCELED',
    },
  ];
};
const statusLabel = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return ['Tamamlandı', 'Bekleniyor', 'Düzenleniyor', 'İptal'];
  }
  return ['Completed', 'In Progress', 'Processing', 'Canceled'];
};
const languageTypes = [
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'tr',
    value: 'Türkçe',
  },
];
const personStatusLabel = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return ['Aktif', 'Pasif', 'Pasif'];
  }
  return ['Active', 'DeActive', 'DeActive'];
};
const toolCheckLinks = {
  AlienTech: 'https://www.alientech-tools.com/vehicles/',
  AutoTuner: 'https://www.autotuner-tool.com/en/compatibility',
  CMDFlash: 'https://www.flashtec.ch/index.php/support',
  MagicMotorsport: 'https://www.magicmotorsport.com/flex/vehicle-and-services-list/full-vehicle-list-for-flex',
  DimSport: 'https://www.dimsport.it/en/applications-list/unique/',
  BFlash: 'https://bflash.eu',

};
const messageSenderEnums = {
  admin: 'ADMIN',
  customer: 'CUSTOMER',
};
const apiEndpoints = {
  merchant: {
    getMerchantCredentials: '/api/merchants/getMerchantCredentials',
  },
  auth: {
    login: '/api/auth/login',
    refresh: '/api/auth/refresh',
    forgotPassword: '/api/auth/forgotPassword',
    resetPassword: '/api/auth/resetPassword',
  },
  admin: {
    getAdmin: '/api/admin/getAdmin',
    updateAdmin: '/api/admin/updateAdmin',
    updatePassword: '/api/admin/updatePassword',
    getWorkingHours: '/api/admin/getWorkingHours',
    updateWorkingHours: '/api/admin/updateWorkingHours',
    getMerchantUsers: '/api/admin/getMerchantUsers',
    getUserBillingInformationForAdmin: '/api/admin/getUserBillingInformationForAdmin',
    getUserSettingsForAdmin: '/api/admin/getUserSettingsForAdmin',
    updateUserSettingsForAdmin: '/api/admin/updateUserSettingsForAdmin',
    getCoupons: '/api/admin/getCoupons',
    createCoupon: '/api/admin/createCoupon',
    deleteCoupon: '/api/admin/deleteCoupon',
    getSolutions: '/api/admin/getSolutions',
    addSolution: '/api/admin/addSolution',
    updateSolution: '/api/admin/updateSolution',
    deleteSolution: '/api/admin/deleteSolution',
    getTuningOptions: '/api/admin/getTuningOptions',
    deleteTuningOption: '/api/admin/deleteTuningOption',
    addTuningOption: '/api/admin/addTuningOption',
    updateTuningOption: '/api/admin/updateTuningOption',
    updateVehicleSolutions: '/api/admin/updateVehicleSolutions',
    getFiles: '/api/admin/getFiles',
    getInvoices: '/api/admin/getInvoices',
    deleteInvoice: '/api/admin/deleteInvoice',
    uploadInvoice: '/api/admin/uploadInvoice',
    uploadChatFile: '/api/admin/uploadChatFile',
    downloadInvoice: '/api/admin/downloadInvoice',
    downloadOrderChatFile: '/api/admin/downloadOrderChatFile',
    downloadOriginalFile: '/api/admin/downloadOriginalFile',
    updateOrderStatusForAdmin: '/api/admin/updateOrderStatusForAdmin',
    updateEditorStatusForAdmin: '/api/admin/updateEditorStatusForAdmin',
    getEditorStatusForAdmin: '/api/admin/getEditorStatusForAdmin',
    updateUserStatusForAdmin: '/api/admin/updateUserStatusForAdmin',
    uploadEcuFile: '/api/admin/uploadEcuFile',
    downloadEcuFile: '/api/admin/downloadEcuFile',
    deleteEcuFile: '/api/admin/deleteEcuFile',
    getUserDetailForAdmin: '/api/admin/getUserDetailForAdmin',
    getOrderDetailForAdmin: '/api/admin/getOrderDetailForAdmin',
    getUserFilesForAdmin: '/api/admin/getUserFilesForAdmin',
    getDashboardData: '/api/admin/getDashboardData',
    getPendingFilesCount: '/api/admin/getPendingFilesCount',
    getOrderChatMessages: '/api/admin/getOrderChatMessages',
    sendOrderChatMessage: '/api/admin/sendOrderChatMessage',
    getMessages: '/api/admin/getMessages',
  },
  vehicle: {
    getVehicleTypes: localStorage.getItem(storageKeys.langCode) === 'tr' ? '/api/vehicle/getVehicleTypesTr' : '/api/vehicle/getVehicleTypes',
    getBrandsForTypes: '/api/vehicle/getBrandsForTypes',
    getModelsForBrands: '/api/vehicle/getModelsForBrands',
    getGenerationsForModels: '/api/vehicle/getGenerationsForModels',
    getEnginesForGenerations: '/api/vehicle/getEnginesForGenerations',
    getVehicleDatasForEngines: '/api/vehicle/getVehicleDatasForEngines',
  },
  ecuData: {
    getAllDtc: '/api/ecuData/getAllDtc',
    getAllBosch: '/api/ecuData/getAllBosch',
  },
};
const orderStatusEnums = {
  CANCELED: 'canceled', INPROGRESS: 'inProgress', PROCESSING: 'processing', COMPLETED: 'completed',
};
const userStatusList = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      {
        key: 'all',
        value: 'TÜMÜ',
      }, {
        key: false,
        value: 'AKTİF',
      },
      {
        key: true,
        value: 'PASİF',
      },
    ];
  }
  return [
    {
      key: 'all',
      value: 'ALL',
    }, {
      key: false,
      value: 'ACTIVE',
    },
    {
      key: true,
      value: 'DEACTIVE',
    },
  ];
};

export {
  orderStatusEnums,
  storageKeys,
  apiEndpoints,
  workingDays,
  workingDaysOpen,
  workingDaysClose,
  weekDay,
  billingTypes,
  currencyTypes,
  toolCheckLinks,
  optionTypeList,
  optionTypeEnums,
  orderStatusList,
  userStatusList,
  messageSenderEnums,
  languageTypes,
  statusLabel,
  personStatusLabel,
};
