import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import person from '../../../assets/images/defaultProfileImage.png';
import arrow from '../../../assets/images/right-arrow.svg';
import SideMenu from '../sideMenu/sideMenu';
import { SwitchButton } from '../inputs/inputs';
import {
  apiEndpoints, storageKeys, weekDay, workingDays, workingDaysClose, workingDaysOpen,
} from '../../../helpers/constant';
import { getSessionValue } from '../../../helpers/sessionManager';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { getTimeFromDateObject, getTimeObject } from '../../../utils/formatter';
import { MainContext } from '../../../context/store';
import { showQuestion } from '../../../context/actions/popUpActions';
import { localStr } from '../../../helpers/language';

function Header() {
  const { state, dispatch } = useContext(MainContext);
  const [userData, setUserData] = useState(null);
  const [sideOpen, setSideOpen] = useState(false);
  const [merchantLogo, setMerchantLogo] = useState(null);
  const [workOn, setWorkOn] = useState(null);
  const [workingHours, setWorkingHours] = useState(null);
  const navigate = useNavigate();
  let interval;
  useEffect(() => {
    getCredentials();
    checkWorkingHours();
    fetchWorkingHours(false);
    getMessages();
    checkProfile();
    eventListener();
    return () => {
      clearInterval(interval);
      window.removeEventListener('workingHoursHeader', () => { });
    };
  }, []);
  function checkProfile() {
    const user = sessionStorage.getItem(storageKeys.user);
    if (user) {
      setUserData(JSON.parse(user));
    } else {
      ServiceRequest({
        endpoint: apiEndpoints.admin.getAdmin,
        loading: false,
      })
        .then((res) => {
          sessionStorage.setItem(storageKeys.user, JSON.stringify(res?.data));
          setUserData(res?.data);
        })
        .catch(() => {});
    }
  }
  function getCredentials() {
    const { logoWhite } = getSessionValue({ key: storageKeys.credentials }) || { logoWhite: null };
    setMerchantLogo(logoWhite);
  }
  function checkWorkingHours() {
    interval = setInterval(() => {
      fetchWorkingHours(true);
      getMessages();
    }, 60000);
  }
  function getMessages() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getMessages,
      loading: false,
    })
      .then((res) => {
        if (res?.data) {
          dispatch.popUpDispatch(
            showQuestion({
              title: 'New Update',
              text: res.data.messageText,
              buttons: [
                {
                  buttonTitle: 'Go to File',
                  buttonClick: () => {
                    navigate('/filedetail', { state: { file: { id: res.data.fileId } } });
                  },
                },
                {
                  buttonTitle: 'Cancel',
                  buttonClick: () => {},
                },
              ],
            }),
          );
        }
      })
      .catch(() => {});
  }
  function fetchWorkingHours(triggerEvent) {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getWorkingHours,
      loading: false,
    })
      .then((res) => {
        const d = new Date();
        const day = d.getDay();
        const isOpen = res?.data[workingDays[day]];
        if (triggerEvent) {
          const event = new CustomEvent('workingHoursPage', { detail: { data: res?.data } });
          window.dispatchEvent(event);
        }
        setWorkOn(isOpen);
        setWorkingHours(res.data);
      })
      .catch(() => {});
  }
  function eventListener() {
    window.addEventListener('workingHoursHeader', (event) => {
      const data = event?.detail?.data;
      if (data) {
        const d = new Date();
        const day = d.getDay();
        const isOpen = data[workingDays[day]];
        setWorkOn(isOpen);
        setWorkingHours(data);
      }
    });
  }
  function toggleWorkingOn() {
    const data = { ...workingHours };
    const d = new Date();
    const day = d.getDay();
    data[workingDays[day]] = !data[workingDays[day]];
    ServiceRequest({
      endpoint: apiEndpoints.admin.updateWorkingHours,
      loading: true,
      method: 'POST',
      body: data,
    })
      .then((res) => {
        const isOpen = res?.data[workingDays[day]];
        setWorkOn(isOpen);
        setWorkingHours(res.data);
        const event = new CustomEvent('workingHoursPage', { detail: { data: res?.data } });
        window.dispatchEvent(event);
      })
      .catch(() => {});
  }
  function getOpenCloseTime() {
    if (!workingHours) {
      return <div />;
    }
    let openCloseDay = '';
    let openCloseTime = '';
    const allHoursData = { ...workingHours };
    const now = new Date();
    const day = now.getDay();
    const doubleDaysWorking = [...workingDays, ...workingDays];
    const doubleDaysOpen = [...workingDaysOpen, ...workingDaysOpen];
    const doubleDaysClose = [...workingDaysClose, ...workingDaysClose];
    const doubleWeekDays = [...weekDay(), ...weekDay()];
    for (let i = day; i < day + 7; i += 1) {
      const nextTime = getTimeObject(allHoursData[workOn ? doubleDaysClose[i] : doubleDaysOpen[i]]);
      if ((i === day) && nextTime > now) {
        openCloseDay = String(localStr()?.header?.t1);
        openCloseTime = getTimeFromDateObject(String(nextTime));
        break;
      } else if ((i !== day) && workOn) {
        openCloseDay = String(doubleWeekDays[i]);
        openCloseTime = '00:00';
        break;
      } else if ((i !== day) && allHoursData[doubleDaysWorking[i]]) {
        openCloseDay = String(doubleWeekDays[i]);
        openCloseTime = getTimeFromDateObject(String(nextTime));
        break;
      }
    }
    return (
      <div>
        {localStr()?.header?.t2}
        {' '}
        {workOn ? localStr()?.header?.t3 : localStr()?.header?.t4}
        :
        {' '}
        <span>
          {openCloseDay}
          {' '}
          {openCloseTime}
        </span>
      </div>
    );
  }
  return (
    <div className="d-flex f-column">
      <SideMenu
        userData={userData}
        show={sideOpen}
        toggleMenu={(e) => {
          setSideOpen(e);
        }}
        updateApp={() => checkProfile()}
      />
      <div className={styles.header}>
        <div className={styles.logoCont} onClick={() => navigate('/')}>
          {merchantLogo && <img alt="ecuFile" src={`data:image/png;base64,${merchantLogo}`} className={styles.logo} />}
        </div>
        <div className={styles.headerCont}>
          <div className="d-flex align-center f1">
            <div className={styles.c1}>
              {localStr()?.header?.t5}
            </div>
            <div className={styles.c2}>
              {workOn !== null ? <SwitchButton toggle={() => toggleWorkingOn()} checked={workOn} /> : <div className="w-40" />}
              <div className={styles.info}>
                <div className={styles.triangleUp} />
                {localStr()?.header?.t6}
                <div className={styles.infoT1}>
                  {localStr()?.header?.t7}
                  {' '}
                  <span>{localStr()?.header?.t8}</span>
                  {' '}
                  {localStr()?.header?.t9}
                </div>
                <div className={styles.infoT2}>
                  {localStr()?.header?.t10}
                  {' '}
                  <span>{localStr()?.header?.t11}</span>
                  {' '}
                  {localStr()?.header?.t12}
                </div>
              </div>
            </div>
            <div className={styles.c4}>
              {getOpenCloseTime()}
            </div>
          </div>
          <div className="d-flex align-center c-pointer" onClick={() => setSideOpen(!sideOpen)}>
            <img alt="profile" src={userData?.logo ? `data:image/png;base64,${userData?.logo}` : person} className={styles.profileImage} />
            <div className="m-l-12">
              <div className={styles.t1}>{localStr()?.header?.t13}</div>
              <div className={styles.t2}>
                {userData?.fullName}
                <img alt="menu" src={arrow} className="m-l-8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(Header);
