import React, { useContext, useEffect, useState } from 'react';
import styles from './sideMenu.module.css';
import person from '../../../assets/images/defaultProfileImage.png';
import close from '../../../assets/images/menu-close.svg';
import side1 from '../../../assets/images/sideMenu/sideMenuIcon1.svg';
import side2 from '../../../assets/images/sideMenu/sideMenuIcon2.svg';
import side5 from '../../../assets/images/sideMenu/sideMenuIcon5.svg';
import photoChange from '../../../assets/images/photoChange.png';
import { PrimaryButton } from '../buttons/buttons';
import { GeneralDropdown, GeneralInput } from '../inputs/inputs';
import Message from '../message/message';
import { clear } from '../../../helpers/sessionManager';
import { showQuestion } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { apiEndpoints, languageTypes, storageKeys } from '../../../helpers/constant';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { resizeImage } from '../../../utils/helperFunctions';
import { onlyNumberFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function SideMenu({
  show, userData, toggleMenu = function () {}, updateApp,
}) {
  const { state, dispatch } = useContext(MainContext);
  // profile
  const [logo, setLogo] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [evcWinOlsNumber, setEvcWinOlsNumber] = useState('');
  const [profileMessage, setProfileMessage] = useState(null);
  //------------------

  // password
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState(null);
  //------------------
  const [preferredLanguage, setPreferredLanguage] = useState({
    key: 'en',
    value: 'English',
  });
  const [selectedMenu, setSelectedMenu] = useState(null);
  function toggle() {
    setSelectedMenu(null);
    toggleMenu();
  }
  useEffect(() => {
    setLanguage();
  }, []);
  function setLanguage() {
    const l = localStorage.getItem(storageKeys.langCode);
    if (l === 'tr') {
      setPreferredLanguage({
        key: 'tr',
        value: 'Türkçe',
      });
    }
  }
  function handleLanguageChange(lang) {
    localStorage.setItem(storageKeys.langCode, lang.key);
    window.location.reload();
  }
  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName);
      setEmail(userData.userName);
      setPhone(userData.phone);
      setEvcWinOlsNumber(userData.winols);
      setLogo(userData.logo);
    }
  }, [userData]);
  useEffect(() => {
    setProfileMessage(null);
    setPasswordMessage(null);
    setOldPassword('');
    setNewPassword('');
    setReNewPassword('');
  }, [selectedMenu]);
  function updateProfile(newLogo) {
    const body = {
      logo: newLogo,
      fullName,
      userName: email,
      phone,
      winols: evcWinOlsNumber,
    };
    ServiceRequest({
      endpoint: apiEndpoints.admin.updateAdmin,
      loading: true,
      errorMessage: false,
      method: 'POST',
      body,
    })
      .then((res) => {
        const { data } = res;
        sessionStorage.setItem(storageKeys.user, JSON.stringify(res.data));
        setProfileMessage({
          messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
        });
        updateApp();
      })
      .catch((error) => {
        setProfileMessage({
          messageType: 'error', title: localStr()?.sideMenu?.t3, text: error.message,
        });
      });
  }
  function updatePassword() {
    if (newPassword === reNewPassword) {
      const body = {
        oldPassword,
        newPassword,
      };
      ServiceRequest({
        endpoint: apiEndpoints.admin.updatePassword,
        loading: true,
        errorMessage: false,
        method: 'POST',
        body,
      })
        .then((res) => {
          setPasswordMessage({
            messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
          });
        })
        .catch((error) => {
          setPasswordMessage({
            messageType: 'error', title: localStr()?.sideMenu?.t3, text: error.message,
          });
        });
    } else {
      setPasswordMessage({
        messageType: 'error', title: localStr()?.sideMenu?.t3, text: localStr()?.sideMenu?.t4,
      });
    }
  }
  function renderDetails() {
    switch (selectedMenu) {
      case 1:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t26}</div>
            <div className={styles.mainArea}>
              <div className={styles.c4}>
                <GeneralInput
                  value={fullName}
                  onChange={(e) => setFullName(e)}
                  required
                  title={localStr()?.sideMenu?.t5}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={email}
                  onChange={(e) => setEmail(e)}
                  required
                  title={localStr()?.sideMenu?.t6}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={phone}
                  onChange={(e) => setPhone(onlyNumberFormat(e))}
                  required
                  title={localStr()?.sideMenu?.t7}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={evcWinOlsNumber}
                  onChange={(e) => setEvcWinOlsNumber(e)}
                  title={localStr()?.sideMenu?.t8}
                  formStyle={styles.form1}
                />
              </div>
              <div className={styles.c5}>
                {profileMessage && (
                  <Message
                    formStyle="m-b-24"
                    toggle={() => setProfileMessage(null)}
                    title={profileMessage.title}
                    text={profileMessage.text}
                    messageType={profileMessage.messageType}
                  />
                )}
                <PrimaryButton
                  buttonTitle={localStr()?.sideMenu?.t9}
                  isGhost={fullName === '' || email === '' || phone === ''}
                  buttonClick={() => updateProfile(logo)}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t27}</div>
            <div className={styles.c4}>
              <GeneralInput
                value={oldPassword}
                onChange={(e) => setOldPassword(e)}
                required
                maxLength={6}
                inputType="password"
                title={localStr()?.sideMenu?.t10}
                formStyle={styles.form1}
              />
              <GeneralInput
                value={newPassword}
                onChange={(e) => setNewPassword(e)}
                required
                maxLength={6}
                inputType="password"
                title={localStr()?.sideMenu?.t11}
                formStyle={styles.form1}
              />
              <GeneralInput
                value={reNewPassword}
                onChange={(e) => setReNewPassword(e)}
                required
                maxLength={6}
                inputType="password"
                title={localStr()?.sideMenu?.t12}
                formStyle={styles.form1}
              />
            </div>
            <div className={styles.c5}>
              {passwordMessage && (
                <Message
                  formStyle="m-b-24"
                  toggle={() => setPasswordMessage(null)}
                  title={passwordMessage.title}
                  text={passwordMessage.text}
                  messageType={passwordMessage.messageType}
                />
              )}
              <PrimaryButton
                isGhost={oldPassword.length < 6 || newPassword.length < 6 || reNewPassword.length < 6}
                buttonTitle={localStr()?.sideMenu?.t13}
                buttonClick={() => updatePassword()}
              />
            </div>
          </>
        );
      default:
        return (
          <div className="d-flex f-column justify-end f1">
            <div className={`${styles.t322} m-t-16 m-b-16 m-l-16`}>
              <span onClick={() => {
                window.open('/agreement');
              }}
              >
                {localStr()?.sideMenu?.t14}
              </span>
            </div>
          </div>
        );
    }
  }
  function askForLogOut() {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.sideMenu?.t15,
        text: localStr()?.sideMenu?.t16,
        buttons: [
          { buttonTitle: localStr()?.sideMenu?.t17, buttonClick: () => clear() },
          {
            buttonTitle: localStr()?.sideMenu?.t18,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  const encodeImageFileAsURL = (file) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resizeImage(reader.result)
        .then((newLogo) => {
          updateProfile(newLogo.split(',')[1]);
          const input = document.getElementById('file');
          if (input) {
            input.value = null;
          }
        })
        .catch(() => {});
    };
    if (file !== undefined && file !== null) {
      reader.readAsDataURL(file);
    }
  };
  function handlePhotoClick() {
    const input = document.getElementById('file');
    if (input) {
      input.click();
    }
  }
  return (
    <div className={`${styles.sideBarBack} ${show && styles.show}`} onClick={() => toggle()}>
      <div className={`${styles.sideBar} ${show && styles.show}`} onClick={(event) => event.stopPropagation()}>
        <div className="d-flex f-column">
          <div className={styles.c1}>
            <div className="d-flex align-center f1">
              <div className="d-flex p-relative c-pointer" onClick={() => handlePhotoClick()}>
                <img
                  alt="profile"
                  src={logo ? `data:image/png;base64,${logo}` : person}
                  className={styles.profileImage}
                />
                <img alt="change" src={photoChange} className={styles.photoChange} />
              </div>
              <input
                type="file"
                id="file"
                autoComplete="off"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(event) => encodeImageFileAsURL(event.target.files[0])}
              />
              <div className="m-l-12">
                <div className={styles.t1}>{localStr()?.sideMenu?.t19}</div>
                <div className={styles.t2}>
                  {userData?.fullName}
                </div>
              </div>
            </div>
            <div className="c-pointer" onClick={() => toggle()}>
              <img alt="close" src={close} />
            </div>
          </div>
          <div className={styles.admin}>
            {localStr()?.sideMenu?.t28}
          </div>
        </div>
        <div className={styles.c2}>
          <div
            className={`${styles.c3} ${selectedMenu === 1 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 1 ? null : 1)}
          >
            <img alt="menu" src={side1} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t20}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t21}</div>
            </div>
          </div>
          <div
            className={`${styles.c3} ${selectedMenu === 2 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 2 ? null : 2)}
          >
            <img alt="menu" src={side2} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t22}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t23}</div>
            </div>
          </div>
          <div
            className={`${styles.c3}`}
            onClick={() => {
              toggle();
              askForLogOut();
            }}
          >
            <img alt="menu" src={side5} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t24}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t25}</div>
            </div>
          </div>
        </div>
        {selectedMenu === null && (
        <div className="m-l-24 m-r-24">
          <GeneralDropdown
            formStyle="m-t-24"
            title={localStr()?.sideMenu?.t1}
            data={languageTypes}
            value={preferredLanguage}
            onChange={(e) => handleLanguageChange(e)}
          />
        </div>
        )}
        {renderDetails()}
      </div>
    </div>
  );
}

export default SideMenu;
