import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './main.module.css';
import icPricingBack from '../../../assets/images/vaultBack.svg';
import icPricing from '../../../assets/images/vault.svg';
import arrow from '../../../assets/images/file-text.svg';
import search from '../../../assets/images/search.svg';
import icCircles from '../../../assets/images/file-arrow-down.svg';
import icChart from '../../../assets/images/chart-line.svg';
import icUp from '../../../assets/images/up-blue.svg';
import icDown from '../../../assets/images/down-red.svg';
import icUsers from '../../../assets/images/users.svg';
import icUsersT from '../../../assets/images/users-three.svg';
import { SecondaryButton } from '../../views/buttons/buttons';
import { SimpleInput } from '../../views/inputs/inputs';
import Pagination from '../../views/pagination/pagination';
import Status from '../../views/status/status';
import eye from '../../../assets/images/eye.svg';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, orderStatusEnums, weekDay } from '../../../helpers/constant';
import { decodeString, priceFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

Chart.register(CategoryScale);
function Main() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [boardData, setBoardData] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    getDashBoardData();
  }, []);
  function getDashBoardData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getDashboardData,
      loading: true,
    })
      .then((res) => {
        setData(res.data.orders);
        setFilteredData(res.data.orders);
        const board = {
          todayCount: res.data.todayCount,
          thisWeekCount: res.data.thisWeekCount,
          thisMonthCount: res.data.thisMonthCount,
          thisYearCount: res.data.thisYearCount,
          fromLastMonthCount: res.data.fromLastMonthCount,
          fromLastQuarterCount: res.data.fromLastQuarterCount,
          monthBalance: res.data.monthBalance,
          totalUserCount: res.data.totalUserCount,
          monthlyUserCount: res.data.monthlyUserCount,
        };
        handleChartData(res.data.dailyData);
        setBoardData(board);
      })
      .catch((error) => { });
  }
  function handleChartData(data) {
    const days = weekDay();
    const d = {
      labels: [],
      datasets: [
        {
          label: 'Files',
          data: [],
          borderColor: '#26C9A2',
          backgroundColor: '#CBF4F0',
          fill: true,
          borderWidth: -5,
        },
      ],
    };
    if (data.filter((e) => e.fileCount !== 0).length !== 0) {
      data.forEach((e) => {
        d.labels.push(days[e.dayIndex - 1]);
        d.datasets[0].data.push(e.fileCount);
      });
      setChartData(d);
    }
  }
  function handleNavigateUserDetail(userId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserDetailForAdmin}/${userId}`,
      loading: true,
    })
      .then((res) => {
        navigate('/userdetail', { state: { user: res?.data } });
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.user?.fullName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      setFilteredData(allData);
    }
  }, [searchTerm]);
  return (
    <BaseView path="/ Dashboard">
      <div className={styles.main}>
        <div className="d-flex">
          <div className={styles.cartContainer}>
            <div className={styles.cartLabel}>
              <div className="d-flex align-center">
                <img alt="files" src={icChart} className="m-r-16" />
                <div className={styles.t1}>
                  {localStr()?.main?.t1}
                </div>
              </div>
              <div className={styles.t2}>
                {localStr()?.main?.t2}
              </div>
              <div className="d-flex align-center">
                <div className={styles.board1}>
                  {localStr()?.main?.t3}
                  <div className="m-l-16">
                    {boardData?.todayCount}
                  </div>
                </div>
                <div className={styles.board1}>
                  {localStr()?.main?.t4}
                  <div>
                    {boardData?.thisWeekCount}
                  </div>
                </div>
                <div className={styles.board1}>
                  {localStr()?.main?.t5}
                  <div className="d-flex align-center">
                    <span className="m-r-24">{boardData?.thisMonthCount}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-center">
                <div className={styles.board1}>
                  {localStr()?.main?.t6}
                  <div className="d-flex align-center">
                    <span className="m-l-16">{boardData?.fromLastMonthCount}</span>
                    <img alt="*" src={boardData?.fromLastMonthCount > 0 ? icUp : icDown} className="m-l-4" />
                  </div>
                </div>
                <div className={styles.board1}>
                  {localStr()?.main?.t7}
                  <div className="d-flex align-center">
                    <span className="m-l-4">{boardData?.fromLastQuarterCount}</span>
                    <img alt="*" src={boardData?.fromLastQuarterCount > 0 ? icUp : icDown} className="m-l-4" />
                  </div>
                </div>
                <div className={styles.board1}>
                  {localStr()?.main?.t8}
                  <div className="d-flex align-center">
                    <span className="m-r-24">{boardData?.thisYearCount}</span>
                  </div>
                </div>
              </div>
            </div>
            {chartData
              ? (
                <Line
                  className={styles.cart}
                  data={chartData}
                  options={options}
                />
              ) : (
                <div className={styles.cartEmpty}>{localStr()?.main?.t9}</div>
              )}
          </div>
          <div className="d-flex f-column f1 m-l-24">
            <div className={styles.pricingCont}>
              <img alt="pricing" src={icPricing} className={styles.icPricing} />
              <div className={styles.t3}>
                {priceFormat(boardData?.monthBalance)}
                {' '}
                ₺
              </div>
              <div className={styles.t4}>
                {localStr()?.main?.t10}
              </div>
              <SecondaryButton
                buttonTitle={localStr()?.main?.t11}
                buttonStyle={styles.pricingButton}
                leftIcon={arrow}
                leftIconStyle={styles.pArrow}
                buttonClick={() => navigate('/invoices')}
              />
              <img alt="pricing" src={icPricingBack} className={styles.pricingBackImg} />
            </div>
            <div className="d-flex align-center m-t-24">
              <div className={styles.users1}>
                <img alt="icon" src={icUsers} />
                <div className={styles.t5}>{boardData?.monthlyUserCount}</div>
                <div className={styles.t6}>{localStr()?.main?.t12}</div>
              </div>
              <div className={styles.users2}>
                <img alt="icon" src={icUsersT} />
                <div className={styles.t5}>{boardData?.totalUserCount}</div>
                <div className={styles.t6}>{localStr()?.main?.t13}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.searchCont}>
          <div className={styles.searchTitleCont}>
            <div className="d-flex f-column f1">
              <div className={styles.t7}>{localStr()?.main?.t14}</div>
              <div className={styles.t8}>{localStr()?.main?.t15}</div>
            </div>
            <img alt="circle" src={icCircles} />
          </div>
          <SimpleInput
            formStyle={styles.search}
            onChange={(e) => setSearchTerm(e)}
            value={searchTerm}
            leftIcon={search}
            placeHolder={localStr()?.main?.t16}
          />
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="text-center w-90">{localStr()?.main?.t17}</th>
                <th className="w-90">{localStr()?.main?.t18}</th>
                <th>{localStr()?.main?.t19}</th>
                <th>{localStr()?.main?.t20}</th>
                <th className="text-center w-90">{localStr()?.main?.t21}</th>
                <th className="text-center w-100">{localStr()?.main?.t22}</th>
                <th className="text-center w-110">{localStr()?.main?.t23}</th>
                <th className="text-center w-50">{localStr()?.main?.t24}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
              <tr>
                <td colSpan={8} className={styles.tableEmpty}>
                  {localStr()?.main?.t25}
                </td>
              </tr>
              )}
              {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  <td className="text-center w-90">{item?.orderId}</td>
                  <td className="w-90">{new Date(item?.createdDate).toLocaleDateString()}</td>
                  <td>
                    <div
                      className="d-flex c-pointer text-underline"
                      onClick={() => handleNavigateUserDetail(item?.user?.id)}
                    >
                      {item?.user?.fullName}
                    </div>
                  </td>
                  <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                  <td className="text-center w-90">{item?.plate}</td>
                  <td className={`${styles.tPrice} w-100 text-center`}>
                    {priceFormat(item?.amount)}
                    {' '}
                    ₺
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="w-110">
                    <div className="d-flex f-column w100 align-center">
                      <Status
                        type={orderStatusEnums[item.orderStatus]}
                      />
                    </div>
                  </td>
                  <td className="text-center c-pointer w-50">
                    <div
                      onClick={() => navigate('/filedetail', { state: { file: item } })}
                    >
                      <img alt="show" src={eye} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length !== 0 && (
          <div className="d-flex m-t-24 align-center">
            <Pagination
              formStyle={styles.pagination}
              selectedPage={selectedPage}
              totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
              onChange={(e) => setSelectedPage(e)}
            />
            {searchTerm === '' && (
            <div className={styles.t31}>
              {localStr()?.main?.t26}
              {' '}
              {(selectedPage - 1) * pageItemCount}
              {' '}
              -
              {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
              {' '}
              {localStr()?.main?.t27}
              {' '}
              {filteredData.length}
            </div>
            )}
          </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const options = {
  responsive: true,
  aspectRatio: 4,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};
export default Main;
