import { loadingStore, toastStore } from '../context/globalStores';
import { LOADING_FALSE, LOADING_TRUE } from '../context/types';
import { apiEndpoints, storageKeys } from './constant';
import { showToastError, showToastSuccess } from '../context/actions/toastActions';
import { clear, getSessionValue, setSessionValue } from './sessionManager';
import { localStr } from './language';

const VehicleServiceRequest = async ({
  endpoint,
  body,
  method = 'GET',
  loading,
}) => {
  if (loading === true) {
    loadingStore.dispatch({ type: LOADING_TRUE });
  }
  const requestBody = {
    ...body,
  };
  const options = {
    method,
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: body && JSON.stringify(requestBody),
  };
  const baseUrl = process.env.REACT_APP_VEHICLE_BASE_URL;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + endpoint, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw JSON.stringify({ status: res.status, body: { message: localStr()?.connectionManager?.t1 } });
      })
      .then((result) => {
        if (loading) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        resolve(result);
      })
      .catch(async (error) => {
        if (loading) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        reject();
      });
  });
};
const ServiceRequest = async ({
  endpoint,
  body,
  method = 'GET',
  loading,
  errorMessage = true,
  successMessage = false,
  formData = false,
  downloadFile = false,
}) => {
  if (loading === true) {
    loadingStore.dispatch({ type: LOADING_TRUE });
  }
  const token = localStorage.getItem(storageKeys.token);
  const options = {
    method,
    headers: formData ? {
      Authorization: endpoint.includes('/api/admin/') && `Bearer ${JSON.parse(token)}`,
    } : {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: endpoint.includes('/api/admin/') && `Bearer ${JSON.parse(token)}`,
    },
    body: formData ? body : (body && JSON.stringify(body)),
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + endpoint, options)
      .then((res) => {
        if (res.ok) {
          if (downloadFile) {
            return res.blob();
          }
          return res.json();
        }
        if (res.status === 420) {
          throw JSON.stringify({ status: res.status, body: { message: localStr()?.connectionManager?.t2 } });
        }
        return res.json().then((j) => {
          throw JSON.stringify({ status: res.status, body: j });
        });
      })
      .then((result) => {
        if (downloadFile) {
          const elm = document.createElement('a');
          elm.href = URL.createObjectURL(result);
          const urlId = endpoint.split('/').at(-1);
          elm.setAttribute('download', `${downloadFile}-${urlId}.zip`);
          elm.click();
          resolve();
        }
        if (loading) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        if (successMessage) {
          toastStore.dispatch(showToastSuccess({ text: result.message }));
        }
        resolve(result);
      })
      .catch(async (error) => {
        if (loading) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        try {
          const parsedError = JSON.parse(error);
          if (parsedError.status !== 420 && errorMessage) {
            toastStore.dispatch(showToastError({ text: JSON.parse(error)?.body?.message }));
          }
          if (parsedError.status === 401) {
            clear();
          }
          if (parsedError.status === 420) {
            const currentRequest = {
              endpoint,
              body,
              method,
              loading,
              errorMessage,
              successMessage,
            };
            try {
              const refreshResponse = await refreshToken(currentRequest);
              resolve(refreshResponse);
            } catch (refreshError) {
              reject(refreshError);
            }
          }
          reject(JSON.parse(error)?.body);
        } catch (e) {
          reject();
        }
      });
  });
};
async function refreshToken(currentRequest) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const endpoint = apiEndpoints.auth.refresh;
  const requestBody = {
    userId: getSessionValue({ key: storageKeys.userId }),
    refreshToken: getSessionValue({ key: storageKeys.refreshToken }),
  };
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(requestBody),
  };
  return new Promise((resolve, reject) => {
    fetch(baseUrl + endpoint, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then((result) => {
        setSessionValue({ key: storageKeys.token, value: result.data.accessToken });
        ServiceRequest(currentRequest)
          .then((serviceResult) => {
            resolve(serviceResult);
          })
          .catch((e) => reject(e));
      })
      .catch(() => {
        clear();
        reject();
      });
  });
}
export { ServiceRequest, VehicleServiceRequest };
