import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styles from './userDetail.module.css';
import BaseView from '../../views/baseView/baseView';
import { SimpleInput, SwitchButton } from '../../views/inputs/inputs';
import search from '../../../assets/images/search.svg';
import { TableSorting } from '../../views/tableComponents/tableComponents';
import icUpload from '../../../assets/images/upload-blue.svg';
import icDelete from '../../../assets/images/Delete.svg';
import Status, { GetStatusLabel } from '../../views/status/status';
import eye from '../../../assets/images/eye.svg';
import Pagination from '../../views/pagination/pagination';
import { ServiceRequest } from '../../../helpers/connectionManager';
import {
  apiEndpoints, billingTypes, currencyTypes, orderStatusEnums, orderStatusList, personStatusLabel,
} from '../../../helpers/constant';
import { decodeString } from '../../../utils/formatter';
import icDownload from '../../../assets/images/download-blue.svg';
import icDelete1 from '../../../assets/images/Delete-1.svg';
import { showQuestion } from '../../../context/actions/popUpActions';
import { zipFile } from '../../../utils/helperFunctions';
import { showToastError } from '../../../context/actions/toastActions';
import { MainContext } from '../../../context/store';
import { GeneralModal } from '../../views/modals/generalModal';
import { localStr } from '../../../helpers/language';

function UserDetail() {
  const { dispatch } = useContext(MainContext);
  const { state } = useLocation();
  const customer = state?.user;
  if (!customer) {
    return <Navigate to="/" />;
  }
  const [user, setUser] = useState(customer);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [statusSelected, setStatusSelected] = useState({
    key: 'all',
    value: localStr()?.userDetail?.t1,
  });
  const [statusModal, setStatusModal] = useState(false);
  const [billingData, setBillingData] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  function getFiles() {
    setSelectedPage(1);
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserFilesForAdmin}/${user.id}`,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((error) => { });
  }
  function askForDelete(orderId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.userDetail?.t2,
        text: localStr()?.userDetail?.t3,
        buttons: [
          { buttonTitle: localStr()?.userDetail?.t4, buttonClick: () => deleteInvoice(orderId) },
          {
            buttonTitle: localStr()?.userDetail?.t5,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteInvoice(orderId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteInvoice}/${orderId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getFiles();
      })
      .catch((err) => {
      });
  }
  const handleInvoiceFile = (file) => {
    zipFile(file)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadInvoice}/${selectedFileId}`,
          loading: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            getFiles();
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
            setSelectedFileId(null);
          })
          .catch((error) => {
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.userDetail?.t6 }));
      });
  };
  function handleBrowseFile(id) {
    setSelectedFileId(id);
    const input = document.getElementById('uploadInvoice');
    if (input) {
      input.click();
    }
  }
  function downloadInvoice(id) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadInvoice}/${id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  useEffect(() => {
    getBillingInformation();
    getUserSettings();
    getFiles();
    getUser();
  }, []);
  function getUser() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserDetailForAdmin}/${user.id}`,
      loading: true,
    })
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3 && statusSelected.key === 'all') {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      if (statusSelected.key !== 'all') {
        allData = allData.filter((e) => e.orderStatus === statusSelected.key);
      }
      setFilteredData(allData);
    }
  }, [searchTerm, statusSelected]);
  function getBillingInformation() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserBillingInformationForAdmin}/${user.id}`,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setBillingData(res.data);
      })
      .catch((err) => {
      });
  }
  function getUserSettings() {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserSettingsForAdmin}/${user.id}`,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setSettingsData(res.data);
      })
      .catch((err) => {
      });
  }
  function updateNotificationSetting() {
    const settings = { ...settingsData };
    settings.emailNotification = !settings.emailNotification;
    updateUserSettings(settings);
  }
  function updateUserSettings(settings) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.updateUserSettingsForAdmin}/${user.id}`,
      errorMessage: false,
      loading: true,
      method: 'POST',
      body: settings,
    })
      .then((res) => {
        setSettingsData(res.data);
      })
      .catch((err) => {
      });
  }
  function navigateLink(link) {
    window.open(link);
  }
  function handleUserStatusChange(status) {
    setStatusModal(false);
    const body = {
      isLocked: status,
    };
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.updateUserStatusForAdmin}/${user?.id}`,
      loading: true,
      method: 'POST',
      successMessage: true,
      body,
    })
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => { });
  }
  return (
    <BaseView path="/ Dashboard / User Detail">
      <input
        type="file"
        id="uploadInvoice"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleInvoiceFile(event.target.files[0])}
      />
      <GeneralModal toggle={() => setStatusModal(false)} visible={statusModal}>
        <div className="p-32 d-flex f-column">
          <div className={styles.t1}>
            {localStr()?.userDetail?.t7}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleUserStatusChange(false)}>
            {GetStatusLabel({ type: 'completed', labelList: personStatusLabel() })}
          </div>
          <div className="m-t-16 c-pointer" onClick={() => handleUserStatusChange(true)}>
            {GetStatusLabel({ type: 'canceled', labelList: personStatusLabel() })}
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex align-start">
          <div className={`${styles.cont} m-r-24`}>
            <div className={styles.title}>{localStr()?.userDetail?.t8}</div>
            <div className={styles.text}>{localStr()?.userDetail?.t9}</div>
            <div className={`${styles.cont1} m-t-12`}>
              <div>{localStr()?.userDetail?.t10}</div>
              <div className={styles.cont12}>{user?.fullName}</div>
            </div>
            <div className={styles.cont1}>
              <div>{localStr()?.userDetail?.t11}</div>
              <div className={`${styles.cont12} c-pointer`}>
                <Status onClick={() => setStatusModal(true)} type={user.locked ? 'canceled' : 'completed'} label={user.locked ? localStr()?.userDetail?.t12 : localStr()?.userDetail?.t13} />
              </div>
            </div>
            <div className={styles.cont1}>
              <div>{localStr()?.userDetail?.t14}</div>
              <div>{user?.companyName}</div>
            </div>
            <div className={styles.cont1}>
              <div>{localStr()?.userDetail?.t15}</div>
              <div>{user?.phone}</div>
            </div>
            <div className={styles.cont1}>
              <div>{localStr()?.userDetail?.t16}</div>
              <div
                onClick={() => navigateLink(`mailto:${user?.userName}?subject=Information`)}
                className={styles.emailText}
              >
                {user?.userName}
              </div>
            </div>
            <div className={styles.cont1}>
              <div>
                {localStr()?.userDetail?.t17}
                <span className={styles.t10}>{localStr()?.userDetail?.t18}</span>
                ”:
              </div>
              <div>
                <SwitchButton
                  toggle={() => updateNotificationSetting()}
                  checked={settingsData?.emailNotification || false}
                />
              </div>
            </div>
            <div className={styles.cont1}>
              <div>{localStr()?.userDetail?.t19}</div>
              <div>{currencyTypes[settingsData?.preferredCurrency]}</div>
            </div>
          </div>
          <div className={styles.cont}>
            <div className={styles.title}>{localStr()?.userDetail?.t20}</div>
            <div
              className={styles.text}
            >
              {billingData ? localStr()?.userDetail?.t21 : localStr()?.userDetail?.t22}
            </div>
            {billingData && (
              <>
                <div className={`${styles.cont1} m-t-12`}>
                  <div>{localStr()?.userDetail?.t23}</div>
                  <div
                    className={styles.cont12}
                  >
                    {billingData?.billingType === billingTypes.company ? localStr()?.userDetail?.t24 : localStr()?.userDetail?.t25}
                  </div>
                </div>
                {billingData?.billingType === billingTypes.company ? (
                  <>
                    <div className={styles.cont1}>
                      <div>{localStr()?.userDetail?.t26}</div>
                      <div>{billingData?.companyName}</div>
                    </div>
                    <div className={styles.cont1}>
                      <div>{localStr()?.userDetail?.t27}</div>
                      <div>{billingData?.taxOffice}</div>
                    </div>
                    <div className={styles.cont1}>
                      <div>{localStr()?.userDetail?.t28}</div>
                      <div>{billingData?.taxId}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.cont1}>
                      <div>{localStr()?.userDetail?.t29}</div>
                      <div>{billingData?.fullName}</div>
                    </div>
                    <div className={styles.cont1}>
                      <div>{localStr()?.userDetail?.t30}</div>
                      <div>{billingData?.idNumber}</div>
                    </div>
                  </>
                )}
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t31}</div>
                  <div>{billingData?.phone}</div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t32}</div>
                  <div className={styles.emailText} onClick={() => navigateLink(`mailto:${billingData?.email}?subject=Information`)}>
                    {billingData?.email}
                  </div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t33}</div>
                  <div>{billingData?.country}</div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t34}</div>
                  <div>{billingData?.province}</div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t35}</div>
                  <div>{billingData?.district}</div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t36}</div>
                  <div>{billingData?.postCode}</div>
                </div>
                <div className={styles.cont1}>
                  <div>{localStr()?.userDetail?.t37}</div>
                  <div>{billingData?.billingAddress}</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.searchCont}>
          <div className={styles.searchTitleCont}>
            <div className="d-flex f-column f1">
              <div className={styles.t7}>{localStr()?.userDetail?.t38}</div>
              <div className={styles.t8}>{localStr()?.userDetail?.t39}</div>
            </div>
          </div>
          <SimpleInput
            formStyle={styles.search}
            onChange={(e) => setSearchTerm(e)}
            value={searchTerm}
            leftIcon={search}
            placeHolder={localStr()?.userDetail?.t40}
          />
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="text-center w-90">{localStr()?.userDetail?.t41}</th>
                <th className="w-90">{localStr()?.userDetail?.t42}</th>
                <th>{localStr()?.userDetail?.t43}</th>
                <th className="text-center w-100">{localStr()?.userDetail?.t44}</th>
                <th className="text-center w-60">{localStr()?.userDetail?.t45}</th>
                <th className="text-center w-90">{localStr()?.userDetail?.t46}</th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="text-center w-110">
                  <TableSorting
                    title={`${localStr()?.userDetail?.t47} (${statusSelected.value})`}
                    items={orderStatusList()}
                    selected={statusSelected}
                    onClick={(e) => setStatusSelected(e)}
                  />
                </th>
                <th className="text-center w-50">{localStr()?.userDetail?.t48}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
                <tr>
                  <td colSpan={8} className={styles.tableEmpty}>
                    {localStr()?.userDetail?.t49}
                  </td>
                </tr>
              )}
              {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  <td className="text-center w-90">{item?.orderId}</td>
                  <td className="w-90">{new Date(item?.createdDate).toLocaleDateString()}</td>
                  <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                  <td className="text-center w-100">{item?.plate}</td>
                  <td className="text-center w-60">
                    {item?.invoice ? (
                      <div className={styles.t41}>
                        <div className="d-flex" onClick={() => downloadInvoice(item.id)}>
                          <img alt="pdf" src={icDownload} className={`${styles.trash} m-r-12`} />
                        </div>
                        <div className={styles.line} />
                        <div className="d-flex" onClick={() => askForDelete(item.id)}>
                          <img alt="pdf" src={icDelete1} className={`${styles.trash} m-l-12`} />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.t41}>
                        <div className="d-flex" onClick={() => handleBrowseFile(item.id)}>
                          <img alt="pdf" src={icUpload} className={`${styles.trash} m-r-12`} />
                        </div>
                        <div className={styles.line} />
                        <div className="d-flex">
                          <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12 c-auto`} />
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="text-center w-90">{item?.master?.fullName}</td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="w-110">
                    <div className="d-flex f-column w100 align-center">
                      <Status
                        type={orderStatusEnums[item.orderStatus]}
                      />
                    </div>
                  </td>
                  <td className="text-center c-pointer w-50">
                    <div
                      onClick={() => navigate('/filedetail', { state: { file: item } })}
                    >
                      <img alt="show" src={eye} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length !== 0 && (
            <div className="d-flex m-t-24 align-center">
              <Pagination
                formStyle={styles.pagination}
                selectedPage={selectedPage}
                totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
                onChange={(e) => setSelectedPage(e)}
              />
              {searchTerm === '' && statusSelected.key === 'all' && (
              <div className={styles.t9}>
                {localStr()?.userDetail?.t50}
                {' '}
                {(selectedPage - 1) * pageItemCount}
                {' '}
                -
                {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
                {' '}
                {localStr()?.userDetail?.t51}
                {' '}
                {filteredData.length}
              </div>
              )}
            </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

export default UserDetail;
