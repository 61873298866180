import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './invoices.module.css';
import icDownload from '../../../assets/images/download-blue.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import icDelete1 from '../../../assets/images/Delete-1.svg';
import search from '../../../assets/images/search.svg';
import Pagination from '../../views/pagination/pagination';
import icUpload from '../../../assets/images/upload-blue.svg';
import icDelete from '../../../assets/images/Delete.svg';
import icPricing from '../../../assets/images/vault.svg';
import icPricingBack from '../../../assets/images/vaultBack.svg';
import { apiEndpoints, orderStatusEnums } from '../../../helpers/constant';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { MainContext } from '../../../context/store';
import { decodeString, priceFormat } from '../../../utils/formatter';
import { showQuestion } from '../../../context/actions/popUpActions';
import { zipFile } from '../../../utils/helperFunctions';
import { showToastError } from '../../../context/actions/toastActions';
import Status from '../../views/status/status';
import { localStr } from '../../../helpers/language';

function Invoices() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [monthlyBalance, setMonthlyBalance] = useState(0);
  const pageItemCount = 50;

  useEffect(() => {
    getInvoices();
  }, []);
  function getInvoices() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getInvoices,
      loading: true,
    })
      .then((res) => {
        setData(res.data.orders);
        setFilteredData(res.data.orders);
        setMonthlyBalance(res.data.monthlyBalance);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.user?.fullName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      setFilteredData(allData);
    }
  }, [searchTerm]);
  function handleNavigateUserDetail(userId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserDetailForAdmin}/${userId}`,
      loading: true,
    })
      .then((res) => {
        navigate('/userdetail', { state: { user: res?.data } });
      })
      .catch((error) => { });
  }
  function askForDelete(orderId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.invoice?.t1,
        text: localStr()?.invoice?.t2,
        buttons: [
          { buttonTitle: localStr()?.invoice?.t3, buttonClick: () => deleteInvoice(orderId) },
          {
            buttonTitle: localStr()?.invoice?.t4,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteInvoice(orderId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteInvoice}/${orderId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getInvoices();
      })
      .catch((err) => {
      });
  }
  const handleInvoiceFile = (file) => {
    zipFile(file)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadInvoice}/${selectedFileId}`,
          loading: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            getInvoices();
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
            setSelectedFileId(null);
          })
          .catch((error) => {
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.invoice?.t5 }));
      });
  };
  function handleBrowseFile(id) {
    setSelectedFileId(id);
    const input = document.getElementById('uploadInvoice');
    if (input) {
      input.click();
    }
  }
  function downloadInvoice(id) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadInvoice}/${id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  return (
    <BaseView path="/ Invoices">
      <input
        type="file"
        id="uploadInvoice"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleInvoiceFile(event.target.files[0])}
      />
      <div className={styles.main}>
        <div className={styles.pricingCont}>
          <div className="d-flex align-center">
            <img alt="pricing" src={icPricing} className={styles.icPricing} />
            <div className={styles.t31}>
              {priceFormat(monthlyBalance)}
              {' '}
              ₺
            </div>
          </div>
          <div className={styles.t4}>
            {localStr()?.invoice?.t6}
          </div>
          <img alt="pricing" src={icPricingBack} className={styles.pricingBackImg} />
        </div>
        <div className={styles.mainTable}>
          <div className={styles.TitleCont}>
            <div className="d-flex f-column f1">
              <div className={styles.t1}>{localStr()?.invoice?.t7}</div>
              <div className={styles.t2}>{localStr()?.invoice?.t8}</div>
            </div>
          </div>
          <SimpleInput
            formStyle={styles.search}
            onChange={(e) => setSearchTerm(e)}
            value={searchTerm}
            leftIcon={search}
            placeHolder={localStr()?.invoice?.t9}
          />
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="text-center w-90">{localStr()?.invoice?.t10}</th>
                <th className="w-80">{localStr()?.invoice?.t11}</th>
                <th>{localStr()?.invoice?.t12}</th>
                <th>{localStr()?.invoice?.t13}</th>
                <th className="text-center w-90">{localStr()?.invoice?.t14}</th>
                <th className="text-center w-110">{localStr()?.invoice?.t15}</th>
                <th className="text-center w-90">{localStr()?.invoice?.t16}</th>
                <th className="w-100 text-center">{localStr()?.invoice?.t17}</th>
                <th className="text-center w-60">{localStr()?.invoice?.t18}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
                <tr>
                  <td colSpan={7} className={styles.tableEmpty}>
                    {localStr()?.invoice?.t19}
                  </td>
                </tr>
              )}
              {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  <td className="text-center w-90">{item?.orderId}</td>
                  <td className="w-80">{new Date(item?.createdDate).toLocaleDateString()}</td>
                  <td>
                    <div
                      className={`${styles.user} c-pointer`}
                      onClick={() => handleNavigateUserDetail(item?.user?.id)}
                    >
                      {item?.user?.fullName}
                    </div>
                  </td>
                  <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                  <td className="w-90 text-center">
                    {item?.plate}
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="w-110">
                    <div className="d-flex f-column w100 align-center">
                      <Status
                        type={orderStatusEnums[item.orderStatus]}
                      />
                    </div>
                  </td>
                  <td className="w-90 text-center">
                    {item?.master?.fullName}
                  </td>
                  <td className={`${styles.tPrice} w-100 text-center`}>
                    {priceFormat(item?.amount)}
                    {' '}
                    ₺
                  </td>
                  <td className={`${styles.tPdf} w-60`}>
                    {item?.invoice ? (
                      <div className={styles.t41}>
                        <div className="d-flex" onClick={() => downloadInvoice(item.id)}>
                          <img alt="pdf" src={icDownload} className={`${styles.trash} m-r-12`} />
                        </div>
                        <div className={styles.line} />
                        <div className="d-flex" onClick={() => askForDelete(item.id)}>
                          <img alt="pdf" src={icDelete1} className={`${styles.trash} m-l-12`} />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.t41}>
                        <div className="d-flex" onClick={() => handleBrowseFile(item.id)}>
                          <img alt="pdf" src={icUpload} className={`${styles.trash} m-r-12`} />
                        </div>
                        <div className={styles.line} />
                        <div className="d-flex">
                          <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12 c-auto`} />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length !== 0 && (
            <div className="d-flex m-t-24 align-center">
              <Pagination
                formStyle={styles.pagination}
                selectedPage={selectedPage}
                totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
                onChange={(e) => setSelectedPage(e)}
              />
              {searchTerm === '' && (
              <div className={styles.t3}>
                {localStr()?.invoice?.t20}
                {' '}
                {(selectedPage - 1) * pageItemCount}
                {' '}
                -
                {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
                {' '}
                {localStr()?.invoice?.t21}
                {' '}
                {filteredData.length}
              </div>
              )}
            </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

export default Invoices;
