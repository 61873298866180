import React, { useEffect, useState } from 'react';
import styles from './vehicleData.module.css';
import BaseView from '../../views/baseView/baseView';
import icData from '../../../assets/images/data.svg';
import icArrow from '../../../assets/images/arrow-r.svg';
import icArrow2 from '../../../assets/images/left-arrow.svg';
import icCheck from '../../../assets/images/check-fat.svg';
import img1 from '../../../assets/images/toolCheckImages/AutoTuner.png';
import img2 from '../../../assets/images/toolCheckImages/AlienTech.png';
import img3 from '../../../assets/images/toolCheckImages/MagicMotorsport.png';
import img4 from '../../../assets/images/toolCheckImages/DimSport.png';
import img5 from '../../../assets/images/toolCheckImages/CMDFlash.png';
import img6 from '../../../assets/images/toolCheckImages/BFlash.png';
import { GeneralDropdown } from '../../views/inputs/inputs';
import { AlternateButton, PrimaryButton } from '../../views/buttons/buttons';
import { apiEndpoints, toolCheckLinks } from '../../../helpers/constant';
import { VehicleServiceRequest } from '../../../helpers/connectionManager';
import { decodeString } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function VehicleData() {
  const [data, setData] = useState(null);
  const [selectedStage, setSelectedStage] = useState(0);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [engineList, setEngineList] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState(null);

  useEffect(() => {
    getVehicleTypes();
  }, []);
  function getVehicleTypes() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleTypes,
      loading: true,
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.type, value: e.label }));
        setVehicleTypes(typeList);
      })
      .catch((err) => {
      });
  }
  function getManufacturerList(type) {
    setSelectedVehicleType(type);
    setSelectedManufacturer(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setModelList([]);
    setYearList([]);
    setEngineList([]);
    const body = {
      vehicleTypes: [type.key],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getBrandsForTypes,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setManufacturerList(typeList);
      })
      .catch((err) => {
      });
  }
  function getModelList(manufecturer) {
    setSelectedManufacturer(manufecturer);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setYearList([]);
    setEngineList([]);
    const body = {
      brandIds: [String(manufecturer.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getModelsForBrands,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setModelList(list);
      })
      .catch((err) => {
      });
  }
  function getYearList(model) {
    setSelectedModel(model);
    setSelectedYear(null);
    setSelectedEngine(null);
    setEngineList([]);
    const body = {
      modelIds: [String(model.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getGenerationsForModels,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setYearList(list);
      })
      .catch((err) => {
      });
  }
  function getEngineList(year) {
    setSelectedYear(year);
    setSelectedEngine(null);
    const body = {
      generationIds: [String(year.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getEnginesForGenerations,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setEngineList(list);
      })
      .catch((err) => {
      });
  }
  function getVehicleData() {
    const body = {
      enginIds: [String(selectedEngine.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleDatasForEngines,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
  }
  function handleOpenToolLink(key) {
    const url = toolCheckLinks[key];
    window.open(url);
  }
  function getToolIcon(toolName) {
    switch (toolName) {
      case 'Alientech KESSv2':
        return img2;
      case 'Autotuner':
        return img1;
      case 'CMDFlash':
        return img5;
      case 'MagicMotorsport Flex':
        return img3;
      case 'Dimsport New Genius':
        return img4;
      case 'Dimsport New Trasdata':
        return null;
      case 'bFlash':
        return img6;
      default:
        return null;
    }
  }
  return (
    <BaseView path="/ Vehicle Data">
      {
          data ? (
            <div className={styles.main}>
              <div className={styles.TitleCont}>
                <div className="d-flex f-column f1">
                  <div className={styles.t1}>{localStr()?.vehicleData?.t1}</div>
                  <div className={styles.t2}>{localStr()?.vehicleData?.t2}</div>
                </div>
              </div>
              <div className="d-flex align-center m-t-24">
                <div
                  className={styles.t4}
                >
                  {decodeString(`${data?.parent?.manufacturer_text} / ${data?.parent?.model_text} / ${data?.parent?.year_text} / ${data?.parent?.name}`)}
                </div>
              </div>
              <div className={styles.c1}>
                {
                        data.allData?.map((item, index) => (
                          <div key={index} className={`${styles.stage} ${selectedStage === index && styles.selected}`} onClick={() => setSelectedStage(index)}>
                            {item.name}
                          </div>
                        ))
                      }
              </div>
              <div className="d-flex m-t-24 f-column">
                <div className="d-flex align-start">
                  <div className={`${styles.cont1} m-r-12`}>
                    <div className={styles.title}>{localStr()?.vehicleData?.t3}</div>
                    <div className={styles.row}>
                      <div className={styles.rowT1}>{localStr()?.vehicleData?.t4}</div>
                      <div className={styles.rowT2}>{data.parent.bhp}</div>
                      <img alt="arrow" src={icArrow} />
                      <div className={styles.rowT3}>
                        {data.allData[selectedStage].bhp}
                        <span> hp</span>
                      </div>
                      <div className={styles.rowT4}>
                        +
                        {data.allData[selectedStage].bhp - data.parent.bhp}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.rowT1}>{localStr()?.vehicleData?.t5}</div>
                      <div className={styles.rowT2}>{data.parent.torque}</div>
                      <img alt="arrow" src={icArrow} />
                      <div className={styles.rowT3}>
                        {data.allData[selectedStage].torque}
                        <span> nm</span>
                      </div>
                      <div className={styles.rowT4}>
                        +
                        {data.allData[selectedStage].torque - data.parent.torque}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cont1} m-l-12`}>
                    <div className={styles.title}>{localStr()?.vehicleData?.t18}</div>
                    <div className={styles.row2}>
                      {data.parent?.options && data.parent?.options !== '' && Array.isArray(JSON.parse(decodeString(data.parent?.options))) && JSON.parse(decodeString(data.parent?.options)).map((e, i) => (
                        <div key={i}>
                          <img alt="check" src={icCheck} />
                          {' '}
                          {e}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="d-flex m-t-24">
                  <div className={`${styles.cont1} m-r-12`}>
                    <div className={styles.title}>{localStr()?.vehicleData?.t6}</div>
                    <div className={styles.c2}>
                      <div className={styles.c3}>
                        {data.parent.tools?.map((e, i) => (getToolIcon(e) ? (
                          <img key={i} alt="logo" src={getToolIcon(e)} className={styles.toolIconBig} />
                        ) : <div key={i} />))}
                      </div>
                      <div className="d-flex f-column m-t-24">
                        <div className={styles.t5}>{localStr()?.vehicleData?.t7}</div>
                        <div className={styles.c3}>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('AutoTuner')}>
                            <img alt="img" src={img1} className={styles.toolIcon} />
                          </div>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('AlienTech')}>
                            <img alt="img" src={img2} className={styles.toolIcon} />
                          </div>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('MagicMotorsport')}>
                            <img alt="img" src={img3} className={styles.toolIcon} />
                          </div>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('DimSport')}>
                            <img alt="img" src={img4} className={styles.toolIcon} />
                          </div>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('CMDFlash')}>
                            <img alt="img" src={img5} className={styles.toolIcon} />
                          </div>
                          <div className={styles.toolIconBack} onClick={() => handleOpenToolLink('BFlash')}>
                            <img alt="img" src={img6} className={styles.toolIcon} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cont1} m-l-12`}>
                    <div className={styles.title}>{String("ECU's")}</div>
                    <div className={styles.c4}>
                      {data.parent.ecus?.map((e, i) => (
                        <div key={i} className="m-r-16 m-t-16">{e}</div>
                      ))}
                    </div>
                  </div>
                </div>
                <AlternateButton
                  buttonTitle={localStr()?.vehicleData?.t8}
                  leftIcon={icArrow2}
                  buttonStyle="m-t-24 w-192"
                  buttonClick={() => setData(null)}
                />
              </div>
            </div>
          )
            : (
              <div className={styles.main}>
                <div className={styles.TitleCont}>
                  <div className="d-flex f-column f1">
                    <div className={styles.t1}>{localStr()?.vehicleData?.t9}</div>
                    <div className={styles.t2}>{localStr()?.vehicleData?.t10}</div>
                  </div>
                </div>
                <div className="d-flex align-center m-t-32">
                  <img alt="upload" src={icData} />
                  <div className={styles.t3}>{localStr()?.vehicleData?.t11}</div>
                </div>
                <div className="d-flex m-t-24">
                  <div className="d-flex f-column f1 m-r-12">
                    <GeneralDropdown
                      title={localStr()?.vehicleData?.t12}
                      required
                      data={vehicleTypes}
                      value={selectedVehicleType}
                      onChange={(e) => getManufacturerList(e)}
                    />
                    <GeneralDropdown
                      title={localStr()?.vehicleData?.t13}
                      required
                      formStyle="m-t-24"
                      data={manufacturerList}
                      value={selectedManufacturer}
                      onChange={(e) => getModelList(e)}
                    />
                    <GeneralDropdown
                      title={localStr()?.vehicleData?.t14}
                      required
                      formStyle="m-t-24"
                      data={modelList}
                      value={selectedModel}
                      onChange={(e) => getYearList(e)}
                    />
                  </div>
                  <div className="d-flex f-column f1 m-l-12">
                    <GeneralDropdown
                      title={localStr()?.vehicleData?.t15}
                      required
                      data={yearList}
                      value={selectedYear}
                      onChange={(e) => getEngineList(e)}
                    />
                    <GeneralDropdown
                      title={localStr()?.vehicleData?.t16}
                      required
                      formStyle="m-t-24"
                      data={engineList}
                      value={selectedEngine}
                      onChange={(e) => setSelectedEngine(e)}
                    />
                  </div>
                </div>
                <div>
                  <PrimaryButton
                    isGhost={!selectedEngine}
                    buttonTitle={localStr()?.vehicleData?.t17}
                    buttonStyle="f-right m-t-24"
                    buttonClick={() => getVehicleData()}
                  />
                </div>
              </div>
            )
        }
    </BaseView>
  );
}

export default VehicleData;
