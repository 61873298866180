import React, { useEffect, useState } from 'react';
import styles from './baseView.module.css';
import Header from '../header/header';
import NavBar from '../navBar/navBar';
import BreadCrumb from '../breadCrump/breadCrumb';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';

function BaseView({ children, path }) {
  const [pendingFilesCount, setPendingFilesCount] = useState(0);
  useEffect(() => {
    getPendingFilesCount();
  }, []);
  function getPendingFilesCount() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getPendingFilesCount,
      loading: true,
    })
      .then((res) => {
        setPendingFilesCount(res.data);
      })
      .catch((error) => { });
  }
  return (
    <div className={styles.baseView}>
      <Header />
      <div className="d-flex f1">
        <NavBar pendingFilesCount={pendingFilesCount} />
        <div className={styles.cont}>
          <BreadCrumb path={path} />
          <div className={styles.pageView}>{children}</div>
        </div>
      </div>
    </div>
  );
}
export default BaseView;
