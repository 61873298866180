import React, { useContext } from 'react';
import { MainContext } from '../../../context/store';
import {
  ERROR_MESSAGE_OFF, INFO_MESSAGE_OFF, QUESTION_MESSAGE_OFF, SUCCESS_MESSAGE_OFF,
} from '../../../context/types';
import { GeneralModal } from './generalModal';
import styles from './popUpModals.module.css';
import icSuccess from '../../../assets/images/check-circle.svg';
import icQuestion from '../../../assets/images/info.svg';
import icInfo from '../../../assets/images/warning.svg';
import icError from '../../../assets/images/x-circle.svg';
import { ButtonSelector, SecondaryButton } from '../buttons/buttons';

function SuccessModal() {
  const { state, dispatch } = useContext(MainContext);
  const {
    title, successModalStatus, buttons = [], text,
  } = state.popUpResponse;
  function toggle() {
    dispatch.popUpDispatch({ type: SUCCESS_MESSAGE_OFF });
  }
  return (
    <GeneralModal visible={successModalStatus} toggle={() => toggle()}>
      <div className={`${styles.popUpBack} ${styles.popUpSuccess}`}>
        <img alt="success" src={icSuccess} />
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className="m-t-32">
          {buttons.length > 0 ? (
            <div className="d-flex">
              {buttons.map((button, index) => (
                <div key={index}>
                  <ButtonSelector
                    {...button}
                    buttonStyle={index === 0 ? styles.successPrimaryButton : styles.successSecondaryButton}
                    buttonClick={() => {
                      toggle();
                      setTimeout(() => {
                        button.buttonClick();
                      }, 350);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <SecondaryButton
              buttonTitle="Tamam"
              buttonStyle={styles.successSecondaryButton}
              buttonClick={() => toggle()}
            />
          )}
        </div>
      </div>
    </GeneralModal>
  );
}

function ErrorModal() {
  const { state, dispatch } = useContext(MainContext);
  const {
    title, errorModalStatus, buttons = [], text,
  } = state.popUpResponse;
  function toggle() {
    dispatch.popUpDispatch({ type: ERROR_MESSAGE_OFF });
  }
  return (
    <GeneralModal visible={errorModalStatus} toggle={() => toggle()}>
      <div className={`${styles.popUpBack} ${styles.popUpError}`}>
        <img alt="success" src={icError} />
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className="m-t-32">
          {buttons.length > 0 ? (
            <div className="d-flex">
              {buttons.map((button, index) => (
                <div key={index}>
                  <ButtonSelector
                    {...button}
                    buttonStyle={index === 0 ? styles.errorPrimaryButton : styles.errorSecondaryButton}
                    buttonClick={() => {
                      toggle();
                      setTimeout(() => {
                        button.buttonClick();
                      }, 350);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <SecondaryButton
              buttonTitle="Tamam"
              buttonStyle={styles.errorSecondaryButton}
              buttonClick={() => toggle()}
            />
          )}
        </div>
      </div>
    </GeneralModal>
  );
}
function InfoModal() {
  const { state, dispatch } = useContext(MainContext);
  const {
    title, infoModalStatus, buttons = [], text,
  } = state.popUpResponse;
  function toggle() {
    dispatch.popUpDispatch({ type: INFO_MESSAGE_OFF });
  }
  return (
    <GeneralModal visible={infoModalStatus} toggle={() => toggle()}>
      <div className={`${styles.popUpBack} ${styles.popUpInfo}`}>
        <img alt="success" src={icInfo} />
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className="m-t-32">
          {buttons.length > 0 ? (
            <div className="d-flex">
              {buttons.map((button, index) => (
                <div key={index}>
                  <ButtonSelector
                    {...button}
                    buttonStyle={index === 0 ? styles.infoPrimaryButton : styles.infoSecondaryButton}
                    buttonClick={() => {
                      toggle();
                      setTimeout(() => {
                        button.buttonClick();
                      }, 350);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <SecondaryButton
              buttonTitle="Tamam"
              buttonStyle={styles.infoSecondaryButton}
              buttonClick={() => toggle()}
            />
          )}
        </div>
      </div>
    </GeneralModal>
  );
}
function QuestionModal() {
  const { state, dispatch } = useContext(MainContext);
  const {
    title, questionModalStatus, buttons = [], text,
  } = state.popUpResponse;
  function toggle() {
    dispatch.popUpDispatch({ type: QUESTION_MESSAGE_OFF });
  }
  return (
    <GeneralModal visible={questionModalStatus} toggle={() => toggle()}>
      <div className={`${styles.popUpBack} ${styles.popUpQuestion}`}>
        <img alt="success" src={icQuestion} />
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className="m-t-32">
          {buttons.length > 0 ? (
            <div className="d-flex">
              {buttons.map((button, index) => (
                <div key={index}>
                  <ButtonSelector
                    {...button}
                    buttonStyle={index === 0 ? styles.questionPrimaryButton : styles.questionSecondaryButton}
                    buttonClick={() => {
                      toggle();
                      setTimeout(() => {
                        button.buttonClick();
                      }, 350);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <SecondaryButton
              buttonTitle="Tamam"
              buttonStyle={styles.questionSecondaryButton}
              buttonClick={() => toggle()}
            />
          )}
        </div>
      </div>
    </GeneralModal>
  );
}

export {
  ErrorModal, InfoModal, SuccessModal, QuestionModal,
};
