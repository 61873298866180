import React from 'react';
import styles from './buttons.module.css';

function PrimaryButton({
  buttonTitle, buttonStyle, buttonClick, isGhost, leftIcon, leftIconStyle, rightIcon, rightIconStyle,
}) {
  return (
    <div
      onClick={() => {
        if (!isGhost) {
          buttonClick();
        }
      }}
      className={`${styles.primaryButton} ${buttonStyle} ${isGhost && styles.ghost}`}
    >
      {leftIcon && (
      <img
        alt="icon"
        src={leftIcon}
        className={`${styles.leftButtonIcon} ${leftIconStyle}`}
      />
      )}
      {buttonTitle}
      {rightIcon && (
      <img
        alt="icon"
        src={rightIcon}
        className={`${styles.rightButtonIcon} ${rightIconStyle}`}
      />
      )}
    </div>
  );
}

function SecondaryButton({
  buttonTitle, buttonStyle, buttonClick, isGhost, leftIcon, leftIconStyle, rightIcon, rightIconStyle,
}) {
  return (
    <div
      onClick={() => {
        if (!isGhost) {
          buttonClick();
        }
      }}
      className={`${styles.secondaryButton} ${buttonStyle} ${isGhost && styles.ghost}`}
    >
      {leftIcon && (
      <img
        alt="icon"
        src={leftIcon}
        className={`${styles.leftButtonIcon} ${leftIconStyle}`}
      />
      )}
      {buttonTitle}
      {rightIcon && (
      <img
        alt="icon"
        src={rightIcon}
        className={`${styles.rightButtonIcon} ${rightIconStyle}`}
      />
      )}
    </div>
  );
}

function AlternateButton({
  buttonTitle, buttonStyle, buttonClick, isGhost, leftIcon, leftIconStyle, rightIcon, rightIconStyle,
}) {
  return (
    <div
      onClick={() => {
        if (!isGhost) {
          buttonClick();
        }
      }}
      className={`${styles.alternateButton} ${buttonStyle} ${isGhost && styles.ghost}`}
    >
      {leftIcon && (
      <img
        alt="icon"
        src={leftIcon}
        className={`${styles.leftButtonIcon} ${leftIconStyle}`}
      />
      )}
      {buttonTitle}
      {rightIcon && (
      <img
        alt="icon"
        src={rightIcon}
        className={`${styles.rightButtonIcon} ${rightIconStyle}`}
      />
      )}
    </div>
  );
}

function GhostButton({
  buttonTitle, buttonStyle, buttonClick, isGhost, leftIcon, leftIconStyle, rightIcon, rightIconStyle,
}) {
  return (
    <div
      onClick={() => {
        if (!isGhost) {
          buttonClick();
        }
      }}
      className={`${styles.ghostButton} ${buttonStyle} ${isGhost && styles.ghost}`}
    >
      {leftIcon && (
      <img
        alt="icon"
        src={leftIcon}
        className={`${styles.leftButtonIcon} ${leftIconStyle}`}
      />
      )}
      {buttonTitle}
      {rightIcon && (
      <img
        alt="icon"
        src={rightIcon}
        className={`${styles.rightButtonIcon} ${rightIconStyle}`}
      />
      )}
    </div>
  );
}

const buttonTypes = {
  PrimaryButton: 'PrimaryButton',
  SecondaryButton: 'SecondaryButton',
  AlternateButton: 'AlternateButton',
  GhostButton: 'GhostButton',
};

function ButtonSelector(props) {
  switch (props?.buttonType) {
    case buttonTypes.PrimaryButton:
      return <PrimaryButton {...props} />;
    case buttonTypes.SecondaryButton:
      return <SecondaryButton {...props} />;
    case buttonTypes.AlternateButton:
      return <AlternateButton {...props} />;
    case buttonTypes.GhostButton:
      return <GhostButton {...props} />;
    default:
      return <PrimaryButton {...props} />;
  }
}

export {
  PrimaryButton, SecondaryButton, AlternateButton, GhostButton, ButtonSelector, buttonTypes,
};
