import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import home1 from '../../../assets/images/menu/house-1.svg';
import home2 from '../../../assets/images/menu/house-2.svg';
import file1 from '../../../assets/images/menu/file-arrow-down.svg';
import file2 from '../../../assets/images/menu/file-arrow-down-1.svg';
import users1 from '../../../assets/images/menu/users.svg';
import users2 from '../../../assets/images/menu/users-1.svg';
import tree1 from '../../../assets/images/menu/vehicle-1.svg';
import tree2 from '../../../assets/images/menu/vehicle-2.svg';
import text1 from '../../../assets/images/menu/newspaper-clipping.svg';
import text2 from '../../../assets/images/menu/newspaper-clipping-1.svg';
import circles1 from '../../../assets/images/menu/circles-three-1.svg';
import circles2 from '../../../assets/images/menu/circles-three-2.svg';
import cpu1 from '../../../assets/images/menu/cpu-1.svg';
import cpu2 from '../../../assets/images/menu/cpu-2.svg';
import faders from '../../../assets/images/menu/faders.svg';
import faders1 from '../../../assets/images/menu/faders-1.svg';
import fadersH from '../../../assets/images/menu/faders-horizontal.svg';
import fadersH1 from '../../../assets/images/menu/faders-horizontal-1.svg';
import timer from '../../../assets/images/menu/timer.svg';
import timer1 from '../../../assets/images/menu/timer-1.svg';
import treeS from '../../../assets/images/menu/tree-structure.svg';
import treeS1 from '../../../assets/images/menu/tree-structure-1.svg';
import ticket from '../../../assets/images/menu/ticket.svg';
import ticket1 from '../../../assets/images/menu/ticket-1.svg';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';

function NavBar({ pendingFilesCount = 0 }) {
  const menus = [
    {
      icon1: home1,
      icon2: home2,
      title: localStr()?.navbar?.t1,
      pathNames: ['/'],
      path: '/',
    },
    {
      icon1: file1,
      icon2: file2,
      title: localStr()?.navbar?.t2,
      pathNames: ['/files', '/filedetail'],
      path: '/files',
    },
    {
      icon1: users2,
      icon2: users1,
      title: localStr()?.navbar?.t3,
      pathNames: ['/users', '/userdetail'],
      path: '/users',
    },
    {
      icon1: tree1,
      icon2: tree2,
      title: localStr()?.navbar?.t4,
      pathNames: ['/vehicle'],
      path: '/vehicle',
    },
    {
      icon1: circles1,
      icon2: circles2,
      title: localStr()?.navbar?.t5,
      pathNames: ['/dtcsearch'],
      path: '/dtcsearch',
    },
    {
      icon1: cpu1,
      icon2: cpu2,
      title: localStr()?.navbar?.t6,
      pathNames: ['/bsearch'],
      path: '/bsearch',
    },
    {
      icon1: text2,
      icon2: text1,
      title: localStr()?.navbar?.t7,
      pathNames: ['/invoices'],
      path: '/invoices',
    },
    {
      icon1: ticket,
      icon2: ticket1,
      title: localStr()?.navbar?.t8,
      pathNames: ['/coupon'],
      path: '/coupon',
    },
    {
      icon1: faders1,
      icon2: faders,
      title: localStr()?.navbar?.t9,
      pathNames: ['/solutions', '/addsolution'],
      path: '/solutions',
    },
    {
      icon1: fadersH1,
      icon2: fadersH,
      title: localStr()?.navbar?.t10,
      pathNames: ['/options', '/addoption'],
      path: '/options',
    },
    {
      icon1: treeS1,
      icon2: treeS,
      title: localStr()?.navbar?.t11,
      pathNames: ['/connect'],
      path: '/connect',
    },
    {
      icon1: timer1,
      icon2: timer,
      title: localStr()?.navbar?.t12,
      pathNames: ['/working'],
      path: '/working',
    },
  ];

  return (
    <div className={styles.navbar}>
      {menus.map((item, index) => (
        <div key={item.title}>
          {index === 1 && <div className={styles.t1}>{localStr()?.navbar?.t13}</div>}
          {index === 3 && <div className={styles.t1}>{localStr()?.navbar?.t14}</div>}
          {index === 8 && <div className={styles.t1}>{localStr()?.navbar?.t15}</div>}
          <NavBarItem {...item} pendingFilesCount={pendingFilesCount} />
        </div>
      ))}
    </div>
  );
}
function NavBarItem({
  icon1, icon2, title, path, pathNames, pendingFilesCount,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  function navigatePage(path) {
    navigate(path);
  }
  function getBadge() {
    if (path === '/files' && pendingFilesCount > 0) {
      return (
        <div className={styles.badge}>
          {pendingFilesCount}
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className={`${styles.menuItem} ${pathNames.includes(pathname) && styles.selected}`}
      onClick={() => navigatePage(path)}
    >
      <img alt="menu" src={pathNames.includes(pathname) ? icon2 : icon1} />
      <div className="m-l-24 f1">{title}</div>
      {getBadge()}
    </div>
  );
}
export default NavBar;
