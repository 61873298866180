import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './solutions.module.css';
import icDelete from '../../../assets/images/Delete-1.svg';
import edit from '../../../assets/images/Edit.svg';
import plus from '../../../assets/images/Plus.svg';
import { SecondaryButton } from '../../views/buttons/buttons';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { showQuestion } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { priceFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function Solutions() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getSolutions,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
  }
  function askForDelete(solutionId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.solutions?.t1,
        text: localStr()?.solutions?.t2,
        buttons: [
          { buttonTitle: localStr()?.solutions?.t3, buttonClick: () => deleteSolution(solutionId) },
          {
            buttonTitle: localStr()?.solutions?.t4,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteSolution(solutionId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteSolution}/${solutionId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getData();
      })
      .catch((err) => {
      });
  }
  return (
    <BaseView path="/ Tuning Solutions">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.solutions?.t5}</div>
            <div className={styles.t2}>{localStr()?.solutions?.t6}</div>
          </div>
          <SecondaryButton
            buttonTitle={localStr()?.solutions?.t7}
            leftIcon={plus}
            buttonClick={() => navigate(('/addsolution'))}
          />
        </div>
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th>{localStr()?.solutions?.t8}</th>
              <th>{localStr()?.solutions?.t9}</th>
              <th className="w-180">{localStr()?.solutions?.t10}</th>
              <th className="text-center w-180">{localStr()?.solutions?.t11}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.solutions?.t12}
                </td>
              </tr>
            )}
            {data.map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td>{item.solutionId}</td>
                <td>{item.displayName}</td>
                <td className={`${styles.cost} w-180`}>
                  {priceFormat(item.cost)}
                  {' '}
                  ₺
                </td>
                <td className="text-center w-180">
                  <div className={styles.t41}>
                    <div className="d-flex" onClick={() => navigate('/addsolution', { state: { solution: item } })}>
                      <img alt="pdf" src={edit} className={`${styles.trash} m-r-12`} />
                    </div>
                    <div className={styles.line} />
                    <div className="d-flex" onClick={() => askForDelete(item.id)}>
                      <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12`} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BaseView>
  );
}

export default Solutions;
