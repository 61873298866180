import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './addOption.module.css';
import { CheckBox, GeneralDropdown, GeneralInput } from '../../views/inputs/inputs';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, optionTypeEnums, optionTypeList } from '../../../helpers/constant';
import { onlyNumberFormat } from '../../../utils/formatter';
import { PrimaryButton } from '../../views/buttons/buttons';
import { localStr } from '../../../helpers/language';

function AddOption() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { option } = state || {};
  const [solutionList, setSolutionList] = useState([]);
  const [optionName, setOptionName] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [type, setType] = useState(null);
  const [cost, setCost] = useState('');
  const [isPetrol, setIsPetrol] = useState(true);
  const [isDiesel, setIsDiesel] = useState(true);
  const [solutions, setSolutions] = useState(new Set());
  useEffect(() => {
    getSolutionData();
    checkOption();
  }, []);
  function checkOption() {
    if (option) {
      setOptionName(option.optionName);
      setAdditionalInfo(option.additionalInfo);
      const i = optionTypeList().findIndex((e) => e.key === option.type);
      setType(i > -1 ? optionTypeList()[i] : null);
      setCost(option.cost);
      setIsPetrol(option.petrol);
      setIsDiesel(option.diesel);
      setSolutions(new Set(option.solutions));
    }
  }
  function getSolutionData() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getSolutions,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setSolutionList(res.data);
      })
      .catch((err) => {
      });
  }
  function toggleSolutionCheck(solutionId) {
    const checkedSolutionList = new Set(solutions);
    if (checkedSolutionList.has(solutionId)) {
      checkedSolutionList.delete(solutionId);
    } else {
      checkedSolutionList.add(solutionId);
    }
    setSolutions(checkedSolutionList);
  }
  function saveOption() {
    const body = {
      optionName,
      additionalInfo,
      type: type.key,
      cost: (cost === '' || type.key === optionTypeEnums.free) ? 0 : cost,
      isPetrol,
      isDiesel,
      solutions: Array.from(solutions),
    };
    ServiceRequest({
      endpoint: option ? `${apiEndpoints.admin.updateTuningOption}/${option.id}` : apiEndpoints.admin.addTuningOption,
      successMessage: true,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
      });
  }
  return (
    <BaseView path={option ? '/ Tuning Options / Update Option' : '/ Tuning Options / Add Option'}>
      <div className={styles.main}>
        <div className={styles.comp}>
          <div className={`${styles.searchTitleCont} border-bottom`}>
            <div className="d-flex f-column f1">
              <div className={styles.t7}>{localStr()?.addOption?.t1}</div>
              <div className={styles.t8}>{localStr()?.addOption?.t2}</div>
            </div>
          </div>
          <div className={styles.cont}>
            <div className="d-flex f-column f2">
              <div className="d-flex m-t-32">
                <GeneralInput
                  value={optionName}
                  onChange={(e) => setOptionName(e)}
                  required
                  title={localStr()?.addOption?.t3}
                  formStyle="f1"
                />
                <GeneralDropdown
                  data={optionTypeList()}
                  required
                  title={localStr()?.addOption?.t4}
                  formStyle="m-l-24 f1"
                  value={type}
                  onChange={(e) => setType(e)}
                />
              </div>
              <div className="d-flex m-t-32">
                <GeneralInput
                  required
                  title={localStr()?.addOption?.t5}
                  formStyle="f1"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e)}
                />
                {type?.key === 'PAID' ? (
                  <GeneralInput
                    required
                    title={`${localStr()?.addOption?.t6} (₺)`}
                    formStyle="m-l-24 f1"
                    value={cost}
                    onChange={(e) => setCost(onlyNumberFormat(e))}
                  />
                ) : <div className="f1 m-l-24 " />}
              </div>
              <div className="d-flex f-column m-t-24">
                <div className={styles.title}>{localStr()?.addOption?.t7}</div>
                <div className="d-flex m-t-16">
                  <div className="d-flex align-center">
                    <CheckBox checked={isPetrol} toggle={() => setIsPetrol(!isPetrol)} />
                    <span className={styles.text1}>{localStr()?.addOption?.t16}</span>
                  </div>
                  <div className="d-flex align-center m-l-24">
                    <CheckBox checked={isDiesel} toggle={() => setIsDiesel(!isDiesel)} />
                    <span className={styles.text1}>{localStr()?.addOption?.t17}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="f1" />
          </div>
          <div className="d-flex f-column f1 m-t-32">
            <div className={styles.t7}>{localStr()?.addOption?.t8}</div>
            <div className={styles.t8}>{localStr()?.addOption?.t9}</div>
          </div>
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="w-50">{' '}</th>
                <th>{localStr()?.addOption?.t10}</th>
                <th>{localStr()?.addOption?.t11}</th>
                <th className="w-110">{localStr()?.addOption?.t12}</th>
              </tr>
            </thead>
            <tbody>
              {solutionList.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.addOption?.t13}
                </td>
              </tr>
              )}
              {solutionList.map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="w-50">
                    <div className="d-flex justify-center">
                      <CheckBox
                        checked={solutions.has(item.id)}
                        toggle={() => toggleSolutionCheck(item.id)}
                        checkboxStyle={styles.checkbox}
                      />
                    </div>
                  </td>
                  <td>{item.solutionId}</td>
                  <td>{item.displayName}</td>
                  <td className={`${styles.cost} w-110`}>
                    {item.cost}
                    {' '}
                    ₺
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <PrimaryButton
              buttonTitle={option ? localStr()?.addOption?.t14 : localStr()?.addOption?.t15}
              buttonStyle="f-right m-t-24"
              isGhost={optionName === '' || type === null || additionalInfo === '' || (type?.key === 'PAID' && cost === '')}
              buttonClick={() => {
                saveOption();
              }}
            />
          </div>
        </div>
      </div>
    </BaseView>
  );
}

export default AddOption;
