import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Main from '../components/pages/main/main';
import Files from '../components/pages/files/files';
import FileDetail from '../components/pages/fileDetail/fileDetail';
import VehicleData from '../components/pages/vehicleData/vehicleData';
import Invoices from '../components/pages/invoices/invoices';
import DtcSearch from '../components/pages/dtcSearch/dtcSearch';
import BoschSearch from '../components/pages/boschSearch/boschSearch';
import Users from '../components/pages/users/users';
import UserDetail from '../components/pages/userDetail/userDetail';
import Solutions from '../components/pages/solutions/solutions';
import AddSolution from '../components/pages/addSolution/addSolution';
import Options from '../components/pages/options/options';
import AddOption from '../components/pages/addOption/addOption';
import VehicleConnect from '../components/pages/vehicleConnect/vehicleConnect';
import WorkingHours from '../components/pages/workingHours/workingHours';
import Coupon from '../components/pages/coupon/coupon';
import Login from '../components/pages/login/login';
import { storageKeys } from '../helpers/constant';
import ForgotPassword from '../components/pages/forgotPassword/forgotPassword';
import CheckMail from '../components/pages/forgotPassword/checkMail';
import ResetPassword from '../components/pages/resetPassword/resetPassword';
import PasswordSaved from '../components/pages/resetPassword/passwordSaved';
import Agreement from '../components/pages/agreement/agreement';

const routes = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgotpassword',
    element: <ForgotPassword />,
  },
  {
    path: '/checkmail',
    element: <CheckMail />,
  },
  {
    path: '/resetpassword/:id',
    element: <ResetPassword />,
  },
  {
    path: '/passwordsaved',
    element: <PasswordSaved />,
  },
  {
    path: '/agreement',
    element: <Agreement />,
  },
  {
    path: '/',
    element: <Main />,
    loader,
  },
  {
    path: '/files',
    element: <Files />,
    loader,
  },
  {
    path: '/filedetail',
    element: <FileDetail />,
    loader,
  },
  {
    path: '/users',
    element: <Users />,
    loader,
  },
  {
    path: '/userdetail',
    element: <UserDetail />,
    loader,
  },
  {
    path: '/vehicle',
    element: <VehicleData />,
    loader,
  },
  {
    path: '/invoices',
    element: <Invoices />,
    loader,
  },
  {
    path: '/dtcsearch',
    element: <DtcSearch />,
    loader,
  },
  {
    path: '/bsearch',
    element: <BoschSearch />,
    loader,
  },
  {
    path: '/solutions',
    element: <Solutions />,
    loader,
  },
  {
    path: '/addsolution',
    element: <AddSolution />,
    loader,
  },
  {
    path: '/options',
    element: <Options />,
    loader,
  },
  {
    path: '/addoption',
    element: <AddOption />,
    loader,
  },
  {
    path: '/connect',
    element: <VehicleConnect />,
    loader,
  },
  {
    path: '/working',
    element: <WorkingHours />,
    loader,
  },
  {
    path: '/coupon',
    element: <Coupon />,
    loader,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);
async function loader() {
  const token = localStorage.getItem(storageKeys.token);
  if (!token) {
    window.location.href = '/login';
  }
  return true;
}
export default routes;
