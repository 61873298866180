import JSZip from 'jszip';
import { toastStore } from '../context/globalStores';
import { showToastInfo } from '../context/actions/toastActions';
import { localStr } from '../helpers/language';

const copyContent = async ({ text }) => {
  try {
    await navigator.clipboard.writeText(text);
    toastStore.dispatch(showToastInfo({ text: localStr()?.helpers?.t1 }));
  } catch (err) {
    console.error(localStr()?.helpers?.t2, err);
  }
};
function resizeImage(base64Str, maxWidth = 80, maxHeight = 80) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let { width } = img;
      let { height } = img;
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}
async function zipFile(file) {
  return new Promise((resolve, reject) => {
    if (file?.size > 20971520) {
      reject();
    }
    const zip = new JSZip();
    zip.file(file.name, file);
    zip.generateAsync({ type: 'blob' })
      .then((zipContent) => {
        resolve(zipContent);
      })
      .catch(() => {
        reject();
      });
  });
}
export { copyContent, resizeImage, zipFile };
