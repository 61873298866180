import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './users.module.css';
import BaseView from '../../views/baseView/baseView';
import search from '../../../assets/images/search.svg';
import eye from '../../../assets/images/eye.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import Pagination from '../../views/pagination/pagination';
import Status from '../../views/status/status';
import { TableSorting } from '../../views/tableComponents/tableComponents';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, userStatusList } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';

function Users() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  const [statusSelected, setStatusSelected] = useState({
    key: 'all',
    value: localStr()?.users?.t1,
  });

  useEffect(() => {
    getUserList();
  }, []);
  function getUserList() {
    ServiceRequest({
      endpoint: apiEndpoints.admin.getMerchantUsers,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch(() => {});
  }
  function getUserStatusLabel(locked) {
    return locked ? <Status type="canceled" label={localStr()?.users?.t2} /> : <Status type="success" label={localStr()?.users?.t3} />;
  }
  function navigateLink(link) {
    window.open(link);
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3 && statusSelected.key === 'all') {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.fullName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.userName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.companyName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      if (statusSelected.key !== 'all') {
        allData = allData.filter((e) => e.locked === statusSelected.key);
      }
      setFilteredData(allData);
    }
  }, [searchTerm, statusSelected]);
  return (
    <BaseView path="/ Portal Users">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.users?.t4}</div>
            <div className={styles.t2}>{localStr()?.users?.t5}</div>
          </div>
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.users?.t6}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="text-center w-50">{localStr()?.users?.t7}</th>
              <th>{localStr()?.users?.t8}</th>
              <th>{localStr()?.users?.t9}</th>
              <th className="w-100">{localStr()?.users?.t10}</th>
              <th>{localStr()?.users?.t11}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className="text-center w-140">
                <TableSorting
                  title={`${localStr()?.users?.t12} (${statusSelected.value})`}
                  items={userStatusList()}
                  selected={statusSelected}
                  onClick={(e) => setStatusSelected(e)}
                />
              </th>
              <th className="text-center w-50">{localStr()?.users?.t13}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
            <tr>
              <td colSpan={7} className={styles.tableEmpty}>
                {localStr()?.users?.t14}
              </td>
            </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="text-center w-50">{item.id}</td>
                <td>{item.fullName}</td>
                <td>{item.companyName}</td>
                <td className="w-100">{item.phone}</td>
                <td>
                  <div
                    onClick={() => navigateLink(`mailto:${item.userName}?subject=Information`)}
                    className={`${styles.email} clip-text`}
                  >
                    {item.userName}
                  </div>
                </td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td className="w-140">
                  <div className="d-flex f-column w100 align-center">
                    {getUserStatusLabel(item.locked)}
                  </div>
                </td>
                <td className="text-center c-pointer w-50">
                  <div
                    onClick={() => navigate('/userdetail', { state: { user: item } })}
                  >
                    <img alt="show" src={eye} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
        <div className="d-flex m-t-24 align-center">
          <Pagination
            formStyle={styles.pagination}
            selectedPage={selectedPage}
            totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
            onChange={(e) => setSelectedPage(e)}
          />
          {searchTerm === '' && statusSelected.key === 'all' && (
          <div className={styles.t3}>
            {localStr()?.users?.t15}
            {' '}
            {(selectedPage - 1) * pageItemCount}
            {' '}
            -
            {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
            {' '}
            {localStr()?.users?.t16}
            {' '}
            {filteredData.length}
          </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default Users;
