import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './files.module.css';
import BaseView from '../../views/baseView/baseView';
import search from '../../../assets/images/search.svg';
import eye from '../../../assets/images/eye.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import Pagination from '../../views/pagination/pagination';
import Status from '../../views/status/status';
import { TableSorting } from '../../views/tableComponents/tableComponents';
import icDownload from '../../../assets/images/download-blue.svg';
import icUpload from '../../../assets/images/upload-blue.svg';
import icDelete from '../../../assets/images/Delete.svg';
import icDelete1 from '../../../assets/images/Delete-1.svg';
import { apiEndpoints, orderStatusEnums, orderStatusList } from '../../../helpers/constant';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { decodeString } from '../../../utils/formatter';
import { showQuestion } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { showToastError } from '../../../context/actions/toastActions';
import { zipFile } from '../../../utils/helperFunctions';
import { localStr } from '../../../helpers/language';

function Files() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  const [statusSelected, setStatusSelected] = useState({
    key: 'all',
    value: localStr()?.files?.t1,
  });
  const [selectedFileId, setSelectedFileId] = useState(null);
  useEffect(() => {
    getFiles();
  }, []);
  function getFiles() {
    setSelectedPage(1);
    ServiceRequest({
      endpoint: apiEndpoints.admin.getFiles,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3 && statusSelected.key === 'all') {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.user?.fullName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      if (statusSelected.key !== 'all') {
        allData = allData.filter((e) => e.orderStatus === statusSelected.key);
      }
      setFilteredData(allData);
    }
  }, [searchTerm, statusSelected]);
  function askForDelete(orderId) {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.files?.t2,
        text: localStr()?.files?.t3,
        buttons: [
          { buttonTitle: localStr()?.files?.t4, buttonClick: () => deleteInvoice(orderId) },
          {
            buttonTitle: localStr()?.files?.t5,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  function deleteInvoice(orderId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.deleteInvoice}/${orderId}`,
      successMessage: true,
      loading: true,
    })
      .then((res) => {
        getFiles();
      })
      .catch((err) => {
      });
  }
  const handleInvoiceFile = (file) => {
    zipFile(file)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.admin.uploadInvoice}/${selectedFileId}`,
          loading: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            getFiles();
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
            setSelectedFileId(null);
          })
          .catch((error) => {
            const input = document.getElementById('uploadInvoice');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: localStr()?.files?.t6 }));
      });
  };
  function handleBrowseFile(id) {
    setSelectedFileId(id);
    const input = document.getElementById('uploadInvoice');
    if (input) {
      input.click();
    }
  }
  function downloadInvoice(id) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.downloadInvoice}/${id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function handleNavigateUserDetail(userId) {
    ServiceRequest({
      endpoint: `${apiEndpoints.admin.getUserDetailForAdmin}/${userId}`,
      loading: true,
    })
      .then((res) => {
        navigate('/userdetail', { state: { user: res?.data } });
      })
      .catch((error) => { });
  }
  return (
    <BaseView path="/ Files">
      <input
        type="file"
        id="uploadInvoice"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleInvoiceFile(event.target.files[0])}
      />
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.files?.t21}</div>
            <div className={styles.t2}>{localStr()?.files?.t7}</div>
          </div>
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.files?.t8}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="text-center w-90">{localStr()?.files?.t9}</th>
              <th className="w-80">{localStr()?.files?.t10}</th>
              <th>{localStr()?.files?.t11}</th>
              <th>{localStr()?.files?.t12}</th>
              <th className="text-center w-90">{localStr()?.files?.t13}</th>
              <th className="text-center w-60">{localStr()?.files?.t14}</th>
              <th className="text-center w-90">{localStr()?.files?.t15}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className="text-center w-110">
                <TableSorting
                  title={`${localStr()?.files?.t16} (${statusSelected.value})`}
                  items={orderStatusList()}
                  selected={statusSelected}
                  onClick={(e) => setStatusSelected(e)}
                />
              </th>
              <th className="text-center w-50">{localStr()?.files?.t17}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.files?.t18}
                </td>
              </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="text-center w-90">{item?.orderId}</td>
                <td className="w-80">{new Date(item?.createdDate).toLocaleDateString()}</td>
                <td>
                  <div
                    className="d-flex c-pointer text-underline text-center"
                    onClick={() => handleNavigateUserDetail(item?.user?.id)}
                  >
                    {item?.user?.fullName}
                  </div>
                </td>
                <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                <td className="text-center w-90">{item?.plate}</td>
                <td className="text-center w-60">
                  {item?.invoice ? (
                    <div className={styles.t41}>
                      <div className="d-flex" onClick={() => downloadInvoice(item.id)}>
                        <img alt="pdf" src={icDownload} className={`${styles.trash} m-r-12`} />
                      </div>
                      <div className={styles.line} />
                      <div className="d-flex" onClick={() => askForDelete(item.id)}>
                        <img alt="pdf" src={icDelete1} className={`${styles.trash} m-l-12`} />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.t41}>
                      <div className="d-flex" onClick={() => handleBrowseFile(item.id)}>
                        <img alt="pdf" src={icUpload} className={`${styles.trash} m-r-12`} />
                      </div>
                      <div className={styles.line} />
                      <div className="d-flex">
                        <img alt="pdf" src={icDelete} className={`${styles.trash} m-l-12 c-auto`} />
                      </div>
                    </div>
                  )}
                </td>
                <td className="text-center w-90">{item?.editor?.fullName}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td className="w-110">
                  <div className="d-flex f-column w100 align-center">
                    <Status
                      type={orderStatusEnums[item.orderStatus]}
                    />
                  </div>
                </td>
                <td className="text-center c-pointer w-50">
                  <div
                    onClick={() => navigate('/filedetail', { state: { file: item } })}
                  >
                    <img alt="show" src={eye} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
        <div className="d-flex m-t-24 align-center">
          <Pagination
            formStyle={styles.pagination}
            selectedPage={selectedPage}
            totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
            onChange={(e) => setSelectedPage(e)}
          />
          {searchTerm === '' && statusSelected.key === 'all' && (
          <div className={styles.t3}>
            {localStr()?.files?.t19}
            {' '}
            {(selectedPage - 1) * pageItemCount}
            {' '}
            -
            {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
            {' '}
            {localStr()?.files?.t20}
            {' '}
            {filteredData.length}
          </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default Files;
