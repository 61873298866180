import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './addSolution.module.css';
import { GeneralInput, GeneralTextArea } from '../../views/inputs/inputs';
import { PrimaryButton } from '../../views/buttons/buttons';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { onlyNumberFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function AddSolution() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { solution } = state || {};
  const [solutionId, setSolutionId] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState('');
  useEffect(() => {
    if (solution) {
      setSolutionId(solution.solutionId);
      setDescription(solution.description);
      setDisplayName(solution.displayName);
      setCost(solution.cost);
    }
  }, []);
  function saveSolution() {
    const body = {
      solutionId,
      displayName,
      description,
      cost,
    };
    ServiceRequest({
      endpoint: solution ? `${apiEndpoints.admin.updateSolution}/${solution.id}` : apiEndpoints.admin.addSolution,
      successMessage: true,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
      });
  }
  return (
    <BaseView path={solution ? '/ Tuning Solutions / Update Solution' : '/ Tuning Solutions / Add Solution'}>
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          {solution ? (
            <div className="d-flex f-column f1">
              <div className={styles.t1}>{localStr()?.addSolution?.t1}</div>
              <div className={styles.t2}>{localStr()?.addSolution?.t2}</div>
            </div>
          ) : (
            <div className="d-flex f-column f1">
              <div className={styles.t1}>{localStr()?.addSolution?.t3}</div>
              <div className={styles.t2}>{localStr()?.addSolution?.t4}</div>
            </div>
          )}
        </div>
        <div className="d-flex m-t-24 f1">
          <div className="d-flex f2">
            <div className="d-flex f-column f1">
              <GeneralInput
                title={localStr()?.addSolution?.t5}
                required
                value={solutionId}
                onChange={(e) => setSolutionId(e)}
              />
              <div className={styles.info}>
                <div>
                  {localStr()?.addSolution?.t6}
                </div>
                <div>
                  {localStr()?.addSolution?.t7}
                </div>
              </div>
            </div>
            <div className="d-flex f-column m-l-24 f1">
              <GeneralInput
                title={localStr()?.addSolution?.t8}
                required
                value={displayName}
                onChange={(e) => setDisplayName(e)}
              />
              <div className={styles.info}>
                <div>
                  {localStr()?.addSolution?.t9}
                </div>
              </div>
            </div>
          </div>
          <div className="f1" />
        </div>
        <div className="d-flex">
          <GeneralTextArea
            title={localStr()?.addSolution?.t10}
            required
            formStyle="m-t-24 f2"
            value={description}
            onChange={(e) => setDescription(e)}
          />
          <div className="f1" />
        </div>
        <div className="d-flex">
          <GeneralInput
            title={`${localStr()?.addSolution?.t11} (₺)`}
            required
            formStyle="m-t-24 f2"
            value={cost}
            onChange={(e) => setCost(onlyNumberFormat(e))}
          />
          <div className="f1" />
        </div>
        <div>
          <PrimaryButton
            buttonTitle={solution ? localStr()?.addSolution?.t12 : localStr()?.addSolution?.t13}
            buttonStyle="f-right m-t-24"
            isGhost={solutionId === '' || displayName === '' || description === '' || cost === ''}
            buttonClick={() => saveSolution()}
          />
        </div>
      </div>
    </BaseView>
  );
}

export default AddSolution;
