import { storageKeys } from './constant';

const localStr = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'en') {
    return enLang;
  }
  if (l === 'tr') {
    return trLang;
  }
  const userLang = navigator.language || navigator.userLanguage;
  if (String(userLang).toLowerCase().includes('tr')) {
    localStorage.setItem(storageKeys.langCode, 'tr');
    return trLang;
  }
  localStorage.setItem(storageKeys.langCode, 'en');
  return enLang;
};

const enLang = {
  sideMenu: {
    t1: 'Language',
    t2: 'Congratulations!',
    t3: 'Warning!',
    t4: 'The passwords you entered do not match!',
    t5: 'Name Surname',
    t6: 'Email Address',
    t7: 'Phone Number',
    t8: 'EVC WinOLS Accunt Number',
    t9: 'Save Changes',
    t10: 'Old Password',
    t11: 'New Password - 6 Characters',
    t12: 'Confirm New Password - 6 Characters',
    t13: 'Update Password',
    t14: 'Terms of Service',
    t15: 'Log Out',
    t16: 'Are you sure you want to log out?',
    t17: 'Yes',
    t18: 'Cancel',
    t19: 'Welcome,',
    t20: 'My Profile',
    t21: 'Update account details',
    t22: 'Password',
    t23: 'Change your password',
    t24: 'Log Out',
    t25: 'Logout the portal',
    t26: 'Information Details',
    t27: 'Update Passwords',
    t28: 'Admin',
  },
  header: {
    t1: 'Today',
    t2: 'Auto',
    t3: 'close',
    t4: 'open',
    t5: 'File service :',
    t6: 'This allows you to update the status of the file service.',
    t7: 'If the switch is',
    t8: '“ON”',
    t9: 'file service status is currently online',
    t10: 'If the switch is',
    t11: '“OFF”',
    t12: 'file service status is currently offline',
    t13: 'Welcome,',
  },
  navbar: {
    t1: 'Dashboard',
    t2: 'File Inbox',
    t3: 'Portal Users',
    t4: 'Vehicle Data',
    t5: 'DTC Search',
    t6: 'Bosch Search',
    t7: 'Invoices',
    t8: 'Coupon Code',
    t9: 'Tuning Solutions',
    t10: 'Tuning Options',
    t11: 'Vehicle Connect',
    t12: 'Working Hours',
    t13: 'FILE SERVICE',
    t14: 'TOOLS',
    t15: 'SETTINGS',
  },
  main: {
    t1: 'Monthly Files',
    t2: 'File analysis',
    t3: 'Today',
    t4: 'This Week',
    t5: 'This Month',
    t6: 'Last Month',
    t7: 'Last Quarter',
    t8: 'This Year',
    t9: 'There are no file operations for this week.',
    t10: 'Total balance this month.',
    t11: 'See Invoices',
    t12: 'New user this month',
    t13: 'Total user registered',
    t14: 'Pending Files',
    t15: 'You can view your pending files in this area and search as you wish.',
    t16: 'Search ID, User Name, Vehicle or Plate',
    t17: 'ID',
    t18: 'DATE',
    t19: 'USER NAME',
    t20: 'VEHICLE',
    t21: 'PLATE',
    t22: 'TOTAL',
    t23: 'STATUS',
    t24: 'ACTION',
    t25: 'You do not have a file that you have processed before.',
    t26: 'Showing',
    t27: 'of',
  },
  files: {
    t1: 'ALL',
    t2: 'Delete Invoice',
    t3: 'Are you sure you want to delete this invoice?',
    t4: 'Yes',
    t5: 'Cancel',
    t6: 'File could not be uploaded!',
    t7: 'You can view your files in this area and search as you wish.',
    t8: 'Search ID, User Name, Vehicle or Plate',
    t9: 'ID',
    t10: 'DATE',
    t11: 'USER NAME',
    t12: 'VEHICLE',
    t13: 'PLATE',
    t14: 'INVOICE',
    t15: 'MASTER',
    t16: 'STATUS',
    t17: 'ACTION',
    t18: 'You do not have a file that you have processed before.',
    t19: 'Showing',
    t20: 'of',
    t21: 'Files',
  },
  fileDetail: {
    t1: 'Delete Modified File',
    t2: 'Are you sure you want to delete the modified file?',
    t3: 'Yes',
    t4: 'Cancel',
    t5: 'File could not be uploaded!',
    t6: 'Delete Invoice',
    t7: 'Are you sure you want to delete this invoice?',
    t8: 'Yes',
    t9: 'Cancel',
    t10: 'File could not be uploaded!',
    t11: 'Do you want to change file state?',
    t12: 'Get Support About This File',
    t13: 'You can ask questions about the file here.',
    t14: 'Remove',
    t15: 'Drag a file here or',
    t16: 'browse',
    t17: 'a file to upload',
    t18: 'Enter your support request here.',
    t19: 'Send',
    t20: 'User Name:',
    t21: 'Status:',
    t22: 'Invoice:',
    t23: 'invoice still waiting to be uploaded.',
    t24: 'Brand:',
    t25: 'Model:',
    t26: 'Year:',
    t27: 'Engine Type:',
    t28: 'Plate:',
    t29: 'Transmission:',
    t30: 'Tool Type:',
    t31: 'File Type:',
    t32: 'Tool Used:',
    t33: 'ECU:',
    t34: 'Note:',
    t35: 'Options:',
    t36: 'Master:',
    t37: 'Coupon:',
    t38: 'Total Price',
    t39: 'Uploaded File:',
    t40: 'Original File',
    t41: 'Update File',
    t42: 'Upload File',
    t43: 'Support History',
    t44: 'You can see your support history in this area.',
    t45: 'Download',
    t46: 'Current editor changed!',
    t47: 'This file is editing by another admin user now.',
    t48: 'Current Editor:',
    t49: 'Do you want to update editor status this file?',
    t50: 'Process file',
    t51: 'Cancel editing',
    t52: 'Cancel',
  },
  users: {
    t1: 'ALL',
    t2: 'Deactive',
    t3: 'Active',
    t4: 'Portal Users',
    t5: 'Manage your portal users.',
    t6: 'Search User Name, Email or Company',
    t7: 'ID',
    t8: 'USER NAME',
    t9: 'COMPANY',
    t10: 'PHONE NUMBER',
    t11: 'EMAIL ADDRESS',
    t12: 'STATUS',
    t13: 'ACTION',
    t14: 'Could not find any result.',
    t15: 'Showing',
    t16: 'of',
  },
  userDetail: {
    t1: 'ALL',
    t2: 'Delete Invoice',
    t3: 'Are you sure you want to delete this invoice?',
    t4: 'Yes',
    t5: 'Cancel',
    t6: 'File could not be uploaded!',
    t7: 'Do you want to change file state?',
    t8: 'User Information',
    t9: 'You can see user information in this area.',
    t10: 'User Name:',
    t11: 'Status:',
    t12: 'DeActive',
    t13: 'Active',
    t14: 'Company:',
    t15: 'Phone Number:',
    t16: 'Email Address:',
    t17: 'Notification with “',
    t18: 'email',
    t19: 'Preferred Currency:',
    t20: 'Billing Information',
    t21: 'You can see billing information in this area.',
    t22: "The user's billing information could not be found.",
    t23: 'Billing Type:',
    t24: 'Company',
    t25: 'Individual',
    t26: 'Company:',
    t27: 'Tax Office:',
    t28: 'Tax ID:',
    t29: 'Name Surname:',
    t30: 'ID Number:',
    t31: 'Phone Number:',
    t32: 'Email Address:',
    t33: 'Country:',
    t34: 'Province:',
    t35: 'District:',
    t36: 'Post Code:',
    t37: 'Billing Address:',
    t38: 'Recently Files',
    t39: 'You can view your files in this area and search as you wish.',
    t40: 'Search ID or Plate',
    t41: 'ID',
    t42: 'DATE',
    t43: 'VEHICLE',
    t44: 'PLATE',
    t45: 'INVOICE',
    t46: 'MASTER',
    t47: 'STATUS',
    t48: 'ACTION',
    t49: 'Can not find any result.',
    t50: 'Showing',
    t51: 'of',
  },
  vehicleData: {
    t1: 'Vehicle Performance Lookup',
    t2: 'Search our database for estimated gains.',
    t3: 'Average Increase',
    t4: 'Power:',
    t5: 'Torque:',
    t6: 'Known Read Methods',
    t7: 'Check your tool’s compatibility:',
    t8: 'Search Again',
    t9: 'Vehicle Performance Lookup',
    t10: 'Search our database for estimated gains.',
    t11: 'Vehicle Search',
    t12: 'Select Vehicle Type',
    t13: 'Select Manufacturer',
    t14: 'Select Model',
    t15: 'Select Build Year',
    t16: 'Select Engine',
    t17: 'View Tuning Options',
    t18: 'Options',
  },
  dtc: {
    t1: 'DTC Search',
    t2: 'Search our database for the meanings of P,U,B,C & DF codes.',
    t3: 'Search DTCs to find their descriptions',
    t4: 'CODE',
    t5: 'DESCRIPTION',
    t6: 'Could not find any result.',
    t7: 'Showing',
    t8: 'of',
  },
  bosch: {
    t1: 'How to Find Your ECU',
    t2: 'Close',
    t3: 'Bosch Search',
    t4: 'Search Bosch ECUs to find the type from the manufacturer number.',
    t5: 'How to Find Your ECU',
    t6: 'Enter 3 or more character for search',
    t7: 'TYPE',
    t8: 'MANUFACTURER NUMBER',
    t9: 'CAR MANUFACTURER',
    t10: 'Could not find any result.',
    t11: 'Showing',
    t12: 'of',
  },
  invoice: {
    t1: 'Delete Invoice',
    t2: 'Are you sure you want to delete this invoice?',
    t3: 'Yes',
    t4: 'Cancel',
    t5: 'File could not be uploaded!',
    t6: 'Last 1 month total balance.',
    t7: 'Invoices',
    t8: 'View and download your invoices.',
    t9: 'Search ID, User Name, Vehicle or Plate',
    t10: 'ID',
    t11: 'DATE',
    t12: 'USER NAME',
    t13: 'VEHICLE',
    t14: 'PLATE',
    t15: 'STATUS',
    t16: 'MASTER',
    t17: 'TOTAL',
    t18: 'INVOICE',
    t19: 'You do not have a invoice yet.',
    t20: 'Showing',
    t21: 'of',
  },
  coupon: {
    t1: 'Delete Coupon',
    t2: 'Are you sure you want to delete this coupon?',
    t3: 'Yes',
    t4: 'Cancel',
    t5: 'Generate a Coupon Code',
    t6: 'After entering the discount rate, your code will be automatically generated.',
    t7: 'Enter discount rate %',
    t8: 'Done',
    t9: 'Generate Code',
    t10: 'Coupon Code',
    t11: 'You can create coupons and view the coupons you have created from this area.',
    t12: 'Generate a Coupon',
    t13: 'COUPON CODE',
    t14: 'DISCOUNT RATE',
    t15: 'STATUS',
    t16: 'ACTION',
    t17: 'Could not find any result.',
    t18: 'Used',
    t19: 'Not Used',
  },
  helpers: {
    t1: 'Text copied successfully',
    t2: 'Failed to copy: ',
  },
  solutions: {
    t1: 'Delete Solution',
    t2: 'Are you sure you want to delete this solution?',
    t3: 'Yes',
    t4: 'Cancel',
    t5: 'Tuning Solutions',
    t6: 'Manage solutions available for file uploads.',
    t7: 'Add Solution',
    t8: 'SOLUTION ID',
    t9: 'DISPLAY NAME',
    t10: 'COST',
    t11: 'ACTION',
    t12: 'Could not find any result.',
  },
  addSolution: {
    t1: 'Update Solution',
    t2: 'Update information for this solution.',
    t3: 'Add a Solution',
    t4: 'Add a new solutions for file uploads.',
    t5: 'Solution ID',
    t6: 'Unique name for description of the solution.',
    t7: 'Ex: Stage_1_for_bmw',
    t8: 'Display Name',
    t9: 'Name for display of the solution. Ex: Stage 1, Stage 2',
    t10: 'Solution Description',
    t11: 'Cost',
    t12: 'Update Solution',
    t13: 'Save Solution',
  },
  options: {
    t1: 'Delete Option',
    t2: 'Are you sure you want to delete this option?',
    t3: 'Yes',
    t4: 'Cancel',
    t5: 'Tuning Options',
    t6: 'Manage options available for file uploads.',
    t7: 'Add Option',
    t8: 'OPTION NAME',
    t9: 'OPTION TYPE',
    t10: 'CONNECTED WITH',
    t11: 'COST',
    t12: 'ACTION',
    t13: 'Could not find any result.',
    t14: 'Paid Option',
    t15: 'Free Option',
  },
  addOption: {
    t1: 'Add a Option',
    t2: 'Add a new option for file uploads.',
    t3: 'Option Name',
    t4: 'Option Type',
    t5: 'Additional Info',
    t6: 'Cost',
    t7: 'Fuel Type:',
    t8: 'Connect with Solutions',
    t9: 'You can connect the option you created to the solution you want.',
    t10: 'SOLUTION ID',
    t11: 'DISPLAY NAME',
    t12: 'COST',
    t13: 'There is no solution you have created before.',
    t14: 'Update Option',
    t15: 'Save Option',
    t16: 'Petrol',
    t17: 'Diesel',
  },
  vehicleConnect: {
    t1: 'Vehicle Options',
    t2: 'Select vehicle information to connect with Tuning Solution.',
    t3: 'Select Vehicle Type',
    t4: 'Search a Vehicle Type',
    t5: 'Select Manufacturer',
    t6: 'Search a Manufacturer',
    t7: 'To choose a “Manufacturer”, please select the “Vehicle” Type first.',
    t8: 'Select Model',
    t9: 'Search a Model',
    t10: 'To choose a “Model”, please select the “Manufacturer” first.',
    t11: 'Select Year',
    t12: 'Search a Year',
    t13: 'To choose a “Year”, please select the “Model” first.',
    t14: 'Select Engine',
    t15: 'Search an Engine',
    t16: 'To choose an “Engine”, please select the “Year” first.',
    t17: 'Connect with Solutions',
    t18: 'Remember, previous solutions for the engine types you selected will be deleted!',
    t19: 'SOLUTION ID',
    t20: 'DISPLAY NAME',
    t21: 'COST',
    t22: 'Could not find any result.',
    t23: 'Connect Solutions',
  },
  workingHours: {
    t1: 'The Monday start time cannot be greater than the end time.',
    t2: 'The Tuesday start time cannot be greater than the end time.',
    t3: 'The Wednesday start time cannot be greater than the end time.',
    t4: 'The Thursday start time cannot be greater than the end time.',
    t5: 'The Friday start time cannot be greater than the end time.',
    t6: 'The Saturday start time cannot be greater than the end time.',
    t7: 'The Sunday start time cannot be greater than the end time.',
    t8: 'Set Your Working Hours',
    t9: 'You can adjust your working hours as you wish according to your own business model.',
    t10: 'Add additional information',
    t11: 'Monday',
    t12: 'Tuesday',
    t13: 'Wednesday',
    t14: 'Thursday',
    t15: 'Friday',
    t16: 'Saturday',
    t17: 'Sunday',
    t18: 'Save Changes',
  },
  connectionManager: {
    t1: 'An error occurred.',
    t2: 'Token expired.',
  },
  login: {
    t1: 'Admin',
    t2: 'Login to your Account',
    t3: 'Please enter your informations.',
    t4: 'Email Address',
    t5: 'Password',
    t6: 'Remember Me',
    t7: 'Forgot Password?',
    t8: 'Login',
  },
  forgotPassword: {
    t1: 'Forgot Password?',
    t2: 'To reset your password, please enter your e-mail address registered in the system.',
    t3: 'Email Address',
    t4: 'Send Reset Link',
    t5: 'Terms of Service',
    t6: 'applied.',
  },
  checkMail: {
    t1: 'Check Your Mail Box',
    t2: 'We sent a link to the address',
    t3: 'registered in the system. You can reset your password using the link.',
    t4: 'Terms of Service',
    t5: 'applied.',
  },
  resetPassword: {
    t1: 'Login',
    t2: 'Reset Password',
    t3: 'Please enter your new password.',
    t4: 'Password',
    t5: 'Re Password',
    t6: 'Save Password',
    t7: 'Terms of Service',
    t8: 'applied.',
  },
  passwordSaved: {
    t1: 'Congratulations!',
    t2: 'Congratulations, your password has been updated successfully. You can login using your new password.',
    t3: 'Login',
    t4: 'Terms of Service',
    t5: 'applied.',
  },
};
const trLang = {
  sideMenu: {
    t1: 'Dil',
    t2: 'Tebrikler!',
    t3: 'Uyarı!',
    t4: 'Girdiğiniz şifreler eşleşmiyor!',
    t5: 'Ad Soyad',
    t6: 'E-Posta Adresi',
    t7: 'Telefon',
    t8: 'EVC WinOLS Numarası',
    t9: 'Değişiklikleri Kaydet',
    t10: 'Eski Şifre',
    t11: 'Yeni Şifre - 6 Karakter',
    t12: 'Yeni Şifre Tekrar - 6 Karakter',
    t13: 'Şifreyi Güncelle',
    t14: 'Kullanım Şartları',
    t15: 'Çıkış Yap',
    t16: 'Çıkış yapmak istediğinize emin misiniz?',
    t17: 'Evet',
    t18: 'Vazgeç',
    t19: 'Hoşgeldin,',
    t20: 'Hesabım',
    t21: 'Profil Bilgilerini Güncelle',
    t22: 'Şifre',
    t23: 'Şifre Güncelle',
    t24: 'Çıkış Yap',
    t25: 'Panelden çıkış yap',
    t26: 'Bilgilendirme Detayı',
    t27: 'Şifre Güncelle',
    t28: 'Yönetici',
  },
  header: {
    t1: 'Bugün',
    t2: 'Otomatik',
    t3: 'kapanış',
    t4: 'açılış',
    t5: 'Dosya Servisi :',
    t6: 'Bu, dosya hizmetinin durumunu güncellemenizi sağlar.',
    t7: 'Eğer anahtar',
    t8: '“AÇIK”',
    t9: 'ise dosya hizmet durumu şu anda çevrimiçi',
    t10: 'Eğer anahtar',
    t11: '“KAPALI”',
    t12: 'ise dosya hizmet durumu şu anda çevrimdışı',
    t13: 'Hoşgeldin,',
  },
  navbar: {
    t1: 'Anasayfa',
    t2: 'Dosyalar',
    t3: 'Kullanıcılar',
    t4: 'Araç Verileri',
    t5: 'DTC Arama',
    t6: 'Bosch Arama',
    t7: 'Faturalar',
    t8: 'Kupon Kodu',
    t9: 'Tuning Çözümleri',
    t10: 'Tuning Seçenekleri',
    t11: 'Araç Eşleştirme',
    t12: 'Çalışma Saatleri',
    t13: 'DOSYA SERVİSİ',
    t14: 'ARAÇLAR',
    t15: 'AYARLAR',
  },
  main: {
    t1: 'Aylık Dosyalar',
    t2: 'Dosya analizi',
    t3: 'Bugün',
    t4: 'Bu Hafta',
    t5: 'Bu Ay',
    t6: 'Geçen Ay',
    t7: 'Son Çeyrek',
    t8: 'Bu Yıl',
    t9: 'Bu hafta için dosya işlemi bulunmamaktadır.',
    t10: 'Bu ay toplam bakiye.',
    t11: 'Faturaları Gör',
    t12: 'Bu ay yeni kullanıcılar',
    t13: 'Toplam kayıtlı kullanıcı',
    t14: 'Bekleyen Dosyalar',
    t15: 'Bu alanda bekleyen dosyalarınızı görüntüleyebilir, arama yapabilirsiniz.',
    t16: 'ID, Kullanıcı Adı, Araç veya Plaka Ara',
    t17: 'ID',
    t18: 'TARİH',
    t19: 'KULLANICI ADI',
    t20: 'ARAÇ',
    t21: 'PLAKA',
    t22: 'TOPLAM',
    t23: 'DURUM',
    t24: 'DETAY',
    t25: 'Daha önce işlem yaptığınız bir dosyanız bulunmamaktadır.',
    t26: 'Gösterilen',
    t27: 'Toplam',
  },
  files: {
    t1: 'TÜMÜ',
    t2: 'Faturayı Sil',
    t3: 'Bu faturayı silmek istediğinizden emin misiniz?',
    t4: 'Evet',
    t5: 'Vazgeç',
    t6: 'Dosya güncellenemedi!',
    t7: 'Bu alandan dosyalarınızı görüntüleyebilir ve arama yapabilirsiniz.',
    t8: 'ID, Kullanıcı Adı, Araç veya Plaka Ara',
    t9: 'ID',
    t10: 'TARİH',
    t11: 'KULLANICI ADI',
    t12: 'ARAÇ',
    t13: 'PLAKA',
    t14: 'FATURA',
    t15: 'EDİTÖR',
    t16: 'DURUM',
    t17: 'DETAY',
    t18: 'Daha önce işlem yaptığınız bir dosyanız bulunmamaktadır.',
    t19: 'Gösterilen',
    t20: 'Toplam',
    t21: 'Dosyalar',
  },
  fileDetail: {
    t1: 'Mod Dosyayı Sil',
    t2: 'Mod dosyayı silmek istediğinizden emin misiniz?',
    t3: 'Evet',
    t4: 'Vazgeç',
    t5: 'Dosya güncellenemedi!',
    t6: 'Faturayı Sil',
    t7: 'Bu faturayı silmek istediğinizden emin misiniz?',
    t8: 'Evet',
    t9: 'Vazgeç',
    t10: 'Dosya yüklenemedi!',
    t11: 'Dosya durumunu değiştirmek istiyor musunuz?',
    t12: 'Dosyanız Hakkında Destek Alın',
    t13: 'Dosyayla ilgili sorularınızı buradan sorabilirsiniz.',
    t14: 'Sil',
    t15: 'Dosyayı buraya sürükle veya',
    t16: 'buradan',
    t17: 'dosya yükle',
    t18: 'Destek talebinizi buraya girin.',
    t19: 'Gönder',
    t20: 'Kullanıcı Adı:',
    t21: 'Durum:',
    t22: 'Fatura:',
    t23: 'Fatura Bekleniyor.',
    t24: 'Marka:',
    t25: 'Model:',
    t26: 'Yıl:',
    t27: 'Motor Tipi:',
    t28: 'Plaka:',
    t29: 'Vites:',
    t30: 'Cihaz Tipi:',
    t31: 'Dosya Tipi:',
    t32: 'Kullanılan Cihaz:',
    t33: 'ECU:',
    t34: 'Not:',
    t35: 'Seçenekler:',
    t36: 'Editör:',
    t37: 'Kupon:',
    t38: 'Toplam Tutar',
    t39: 'Yüklenen Dosya:',
    t40: 'Orjinal Dosya',
    t41: 'Dosyayı Güncelle',
    t42: 'Dosya Yükle',
    t43: 'Destek Geçmişi',
    t44: 'Destek geçmişinizi bu alanda görebilirsiniz.',
    t45: 'İndir',
    t46: 'Dosya işleniyor!',
    t47: 'Bu dosya şu anda başka bir yönetici kullanıcı tarafından düzenleniyor',
    t48: 'Düzenleyen:',
    t49: 'Dosya düzenleme durumunu değiştirmek istiyor musunuz?',
    t50: 'Dosyayı işleme al',
    t51: 'Düzenleme işlemini iptal et',
    t52: 'Vazgeç',
  },
  users: {
    t1: 'TÜMÜ',
    t2: 'Pasif',
    t3: 'Aktif',
    t4: 'Panel Kullanıcıları',
    t5: 'Panel kullanıcılarını yönet.',
    t6: 'Kullanıcı Adı, E-Posta veya Firma Ara',
    t7: 'ID',
    t8: 'KULLANICI ADI',
    t9: 'FİRMA',
    t10: 'TELEFON',
    t11: 'E-POSTA',
    t12: 'DURUM',
    t13: 'DETAY',
    t14: 'Herhangi bir sonuç bulunamadı.',
    t15: 'Gösterilen',
    t16: 'Toplam',
  },
  userDetail: {
    t1: 'TÜMÜ',
    t2: 'Faturayı Sil',
    t3: 'Bu faturayı silmek istediğinizden emin misiniz?',
    t4: 'Evet',
    t5: 'Vazgeç',
    t6: 'Dosya Yüklenemedi!',
    t7: 'Dosya durumunu değiştirmek istiyor musunuz?',
    t8: 'Kullanıcı Bilgileri',
    t9: 'Kullanıcı bilgilerini bu alanda görebilirsiniz.',
    t10: 'Kullanıcı Adı:',
    t11: 'Durum:',
    t12: 'Pasif',
    t13: 'Aktif',
    t14: 'Firma:',
    t15: 'Telefon:',
    t16: 'E-Posta Adresi:',
    t17: 'Bildirim açık “',
    t18: 'e-posta',
    t19: 'Döviz Kuru:',
    t20: 'Fatura Bilgileri',
    t21: 'Fatura bilgilerini bu alandan görebilirsiniz.',
    t22: 'Kullanıcının fatura bilgileri bulunamadı.',
    t23: 'Fatura Tipi:',
    t24: 'Kurumsal',
    t25: 'Bireysel',
    t26: 'Firma:',
    t27: 'Vergi Dairesi:',
    t28: 'Vergi Numarası:',
    t29: 'Ad Soyad:',
    t30: 'Kimlik Numarası:',
    t31: 'Telefon:',
    t32: 'E-Posta Adresi:',
    t33: 'Ülke:',
    t34: 'Şehir:',
    t35: 'İlçe:',
    t36: 'Posta Kodu:',
    t37: 'Fatura Adresi:',
    t38: 'Son Dosyalar',
    t39: 'Bu alandan dosyaları görüntüleyebilir ve arama yapabilirsiniz.',
    t40: 'ID veya Plaka Ara',
    t41: 'ID',
    t42: 'TARİH',
    t43: 'ARAÇ',
    t44: 'PLAKA',
    t45: 'FATURA',
    t46: 'EDİTÖR',
    t47: 'DURUM',
    t48: 'DETAY',
    t49: 'Herhangi bir sonuç bulunamadı.',
    t50: 'Gösterilen',
    t51: 'Toplam',
  },
  vehicleData: {
    t1: 'Araç Performansı Görüntüleme',
    t2: 'Tahmini kazançlar için arama yapın.',
    t3: 'Ortalama Artış',
    t4: 'Güç:',
    t5: 'Tork:',
    t6: 'Bilinen Okuma Yöntemleri',
    t7: 'Cihazınızın uyumluluğunu kontrol edin:',
    t8: 'Yeniden Ara',
    t9: 'Araç Performansı Görüntüleme',
    t10: 'Tahmini kazançlar için arama yapın.',
    t11: 'Araç Arama',
    t12: 'Araç Tipi',
    t13: 'Marka',
    t14: 'Model',
    t15: 'Yıl',
    t16: 'Motor',
    t17: 'Tuning Seçeneklerini Gör',
    t18: 'Seçenekler',
  },
  dtc: {
    t1: 'DTC Arama',
    t2: 'P,U,B,C & DF kodlarının anlamlarını veritabanımızda arayın.',
    t3: 'Açıklamalarını bulmak için DTC\'leri arayın',
    t4: 'KOD',
    t5: 'AÇIKLAMA',
    t6: 'Herhangi bir sonuç bulunamadı.',
    t7: 'Gösterilen',
    t8: 'Toplam',
  },
  bosch: {
    t1: 'ECU\'nuzu Nasıl Bulabilirsiniz?',
    t2: 'Kapat',
    t3: 'Bosch Arama',
    t4: 'Üretici numarasından türü bulmak için Bosch ECU\'larını arayın.',
    t5: 'ECU\'nuzu Nasıl Bulabilirsiniz?',
    t6: 'Arama için 3 veya daha fazla karakter girin',
    t7: 'TİP',
    t8: 'ÜRETİCİ NUMARASI',
    t9: 'ARAÇ NUMARASI',
    t10: 'Herhangi bir sonuç bulunamadı.',
    t11: 'Gösterilen',
    t12: 'Toplam',
  },
  invoice: {
    t1: 'Faturayı Sil',
    t2: 'Bu faturayı silmek istediğinizden emin misiniz?',
    t3: 'Evet',
    t4: 'Vazgeç',
    t5: 'Dosya yüklenemedi!',
    t6: 'Son 1 ayın toplam bakiyesi.',
    t7: 'Faturalar',
    t8: 'Faturaları görüntüleyin ve indirin.',
    t9: 'ID, Kullanıcı Adı, Araç veya Plaka Ara',
    t10: 'ID',
    t11: 'TARİH',
    t12: 'KULLANICI ADI',
    t13: 'ARAÇ',
    t14: 'PLAKA',
    t15: 'DURUM',
    t16: 'EDİTÖR',
    t17: 'TOPLAM',
    t18: 'FATURA',
    t19: 'Henüz bir faturanız yok.',
    t20: 'Gösterilen',
    t21: 'Toplam',
  },
  coupon: {
    t1: 'Kuponu Sil',
    t2: 'Bu kuponu silmek istediğinizden emin misiniz?',
    t3: 'Evet',
    t4: 'Vazgeç',
    t5: 'Kupon Oluştur',
    t6: 'İndirim oranını girdikten sonra kodunuz otomatik olarak oluşturulacaktır.',
    t7: 'İndirim oranını giriniz %',
    t8: 'Tamam',
    t9: 'Kupon Oluştur',
    t10: 'Kupon Kodu',
    t11: 'Bu alandan kupon oluşturabilir ve oluşturduğunuz kuponları görüntüleyebilirsiniz.',
    t12: 'Kupon Oluştur',
    t13: 'KUPON KODU',
    t14: 'İNDİRİM ORANI',
    t15: 'DURUM',
    t16: 'DETAY',
    t17: 'Herhangi bir sonuç bulunamadı',
    t18: 'Kullanıldı',
    t19: 'Kullanılmadı',
  },
  helpers: {
    t1: 'Kopyalandı',
    t2: 'Kopyalanamadı: ',
  },
  solutions: {
    t1: 'Çözümü Sil',
    t2: 'Bu çözümü silmek istediğinizden emin misiniz?',
    t3: 'Evet',
    t4: 'Vazgeç',
    t5: 'Tuning Çözümleri',
    t6: 'Dosya yüklemeleri için kullanılabilir çözümleri yönetin.',
    t7: 'Çözüm Ekle',
    t8: 'ÇÖZÜM KODU',
    t9: 'GÖRÜNEN AD',
    t10: 'TUTAR',
    t11: 'DETAY',
    t12: 'Herhangi bir sonuç bulunamadı.',
  },
  addSolution: {
    t1: 'Çözümü Güncelle',
    t2: 'Bu çözüme ilişkin bilgileri güncelleyin.',
    t3: 'Çözüm Ekle',
    t4: 'Dosya yüklemeleri için yeni çözümler ekleyin.',
    t5: 'Çözüm Kodu',
    t6: 'Çözümün tanımı için benzersiz isim.',
    t7: 'Ör: Stage_1_bmw_için',
    t8: 'Görünen Ad',
    t9: 'Çözümün görüntülenmesi için ad. Ör: Stage 1, Stage 2',
    t10: 'Çözüm Açıklaması',
    t11: 'Tutar',
    t12: 'Çözümü Güncelle',
    t13: 'Çözümü Kaydet',
  },
  options: {
    t1: 'Seçeneği Sil',
    t2: 'Bu seçeneği silmek istediğinizden emin misiniz?',
    t3: 'Evet',
    t4: 'Vazgeç',
    t5: 'Tuning Seçenekleri',
    t6: 'Dosya yüklemeleri için kullanılabilir seçenekleri yönetin.',
    t7: 'Seçenek Ekle',
    t8: 'SEÇENEK ADI',
    t9: 'SEÇENEK TİPİ',
    t10: 'BAĞLI ÇÖZÜMLER',
    t11: 'TUTAR',
    t12: 'DETAY',
    t13: 'Herhangi bir sonuç bulunamadı.',
    t14: 'Ücretli Seçenek',
    t15: 'Ücretsiz Seçenek',
  },
  addOption: {
    t1: 'Seçenek ekle',
    t2: 'Dosya yüklemeleri için yeni bir seçenek ekleyin.',
    t3: 'Seçenek Adı',
    t4: 'Seçenek Tipi',
    t5: 'Ek Bilgi',
    t6: 'Tutar',
    t7: 'Yakıt Tipi:',
    t8: 'Çözümlere bağlayın',
    t9: 'Oluşturduğunuz seçeneği istediğiniz çözüme bağlayabilirsiniz.',
    t10: 'ÇÖZÜM KODU',
    t11: 'GÖRÜNEN AD',
    t12: 'TUTAR',
    t13: 'Daha önce oluşturduğunuz bir çözüm bulunmamaktadır.',
    t14: 'Seçeneği Güncelle',
    t15: 'Seçeneği Kaydet',
    t16: 'Benzin',
    t17: 'Dizel',
  },
  vehicleConnect: {
    t1: 'Araç Seçenekleri',
    t2: 'Tuning Çözümlerine bağlanmak için araç bilgilerini seçin.',
    t3: 'Araç Tipi',
    t4: 'Araç Tipi Ara',
    t5: 'Marka Seç',
    t6: 'Marka Ara',
    t7: '“Marka” seçmek için lütfen önce “Araç Tipini” seçin.',
    t8: 'Model Seç',
    t9: 'Model Ara',
    t10: 'Bir “Model” seçmek için lütfen önce “Marka”yı seçin.',
    t11: 'Yıl Seç',
    t12: 'Yıl Ara',
    t13: '“Yıl” seçmek için lütfen önce “Model”i seçin.',
    t14: 'Motor Seç',
    t15: 'Motor Ara',
    t16: '“Motor” seçmek için lütfen önce “Yıl”ı seçin.',
    t17: 'Çözümlerle Bağlantı Kurun',
    t18: 'Seçtiğiniz motor tiplerine ait önceki çözümlerin silineceğini unutmayın!',
    t19: 'ÇÖZÜM KODU',
    t20: 'GÖRÜNEN AD',
    t21: 'TUTAR',
    t22: 'Herhangi bir sonuç bulunamadı.',
    t23: 'Çözümleri Bağla',
  },
  workingHours: {
    t1: 'Pazartesi başlangıç saati bitiş saatinden büyük olamaz.',
    t2: 'Salı başlangıç saati bitiş saatinden büyük olamaz.',
    t3: 'Çarşamba başlangıç saati bitiş saatinden büyük olamaz.',
    t4: 'Perşembe başlangıç saati bitiş saatinden büyük olamaz.',
    t5: 'Cuma başlangıç saati bitiş saatinden büyük olamaz.',
    t6: 'Cumartesi başlangıç saati bitiş saatinden büyük olamaz.',
    t7: 'Pazar başlangıç saati bitiş saatinden büyük olamaz.',
    t8: 'Çalışma Saatlerini Ayarlayın',
    t9: 'Çalışma saatlerinizi kendi iş modelinize göre dilediğiniz gibi ayarlayabilirsiniz.',
    t10: 'Ek bilgi ekleyin',
    t11: 'Pazartesi',
    t12: 'Salı',
    t13: 'Çarşamba',
    t14: 'Parşembe',
    t15: 'Cuma',
    t16: 'Cumartesi',
    t17: 'Pazar',
    t18: 'Değişiklikleri Kaydet',
  },
  connectionManager: {
    t1: 'Bir Hata Oluştu.',
    t2: 'Zaman Aşımı.',
  },
  login: {
    t1: 'Yönetici',
    t2: 'Oturum Aç',
    t3: 'Oturum açma bilgilerinizi girin.',
    t4: 'E-Posta Adresi',
    t5: 'Şifre',
    t6: 'Beni Hatırla',
    t7: 'Şifremi Unuttum?',
    t8: 'Oturum Aç',
  },
  forgotPassword: {
    t1: 'Şifrenizi mi unuttunuz?',
    t2: 'Şifrenizi sıfırlamak için lütfen sistemde kayıtlı e-posta adresinizi giriniz.',
    t3: 'E-Posta Adresi',
    t4: 'Sıfırlama Linki Gönder',
    t5: 'Kullanım Sözleşmesini',
    t6: 'kabul ediyorum.',
  },
  checkMail: {
    t1: 'Posta Kutunuzu Kontrol Edin',
    t2: 'Sistemde kayıtlı olan',
    t3: 'adresine şifre sıfırlama linki gönderdik. Gönderdiğimiz linki kullanarak sıfırlama yapabilirsiniz.',
    t4: 'Kullanım Sözleşmesini',
    t5: 'kabul ediyorum.',
  },
  resetPassword: {
    t1: 'Oturum Aç',
    t2: 'Şifre Sıfırla',
    t3: 'Lütfen yeni şifrenizi girin',
    t4: 'Şifre',
    t5: 'Şifre Tekrar',
    t6: 'Şifreyi Kaydet',
    t7: 'Kullanım Sözleşmesini',
    t8: 'kabul ediyorum.',
  },
  passwordSaved: {
    t1: 'Tebrikler!',
    t2: 'Tebrikler, şifreniz başarıyla değiştirildi. Yeni şifrenizi kullanarak oturum açabilirsiniz.',
    t3: 'Oturum Aç',
    t4: 'Kullanım Sözleşmesini',
    t5: 'kabul ediyorum.',
  },
};

export { localStr };
