import { storageKeys } from './constant';
import { authStore } from '../context/globalStores';
import { loginFail, loginSuccess } from '../context/actions/authActions';

function getSessionValue({ key }) {
  return JSON.parse(localStorage.getItem(key));
}

function setSessionValue({ key, value }) {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeSessionValue({ key }) {
  localStorage.removeItem(key);
}

function loginUser({
  token, refreshToken, userId,
}) {
  localStorage.setItem(storageKeys.userId, JSON.stringify(userId));
  localStorage.setItem(storageKeys.token, JSON.stringify(token));
  localStorage.setItem(storageKeys.refreshToken, JSON.stringify(refreshToken));
  window.location.href = '/';
  authStore.dispatch(loginSuccess());
}

function setCredentials({ credentials }) {
  localStorage.setItem(storageKeys.credentials, JSON.stringify(credentials));
}

function clear() {
  localStorage.clear();
  sessionStorage.clear();
  authStore.dispatch(loginFail());
  window.location.href = '/login';
}

export {
  getSessionValue,
  setSessionValue,
  removeSessionValue,
  loginUser,
  clear,
  setCredentials,
};
